import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-administer',
  templateUrl: './add-administer.component.html',
  styleUrls: ['./add-administer.component.css']
})
export class AddAdministerComponent implements OnInit {
  addAdmin: FormGroup;
  editImage: any;
  subAdminData: any;
  newArr: any=[];
  profile: any;
  userData: any;
  file: any;
  imageUrl1: any;
  imageType: any;
  
aaa:boolean=false;
  selectedItemsList: { id: string; label: string; isChecked: boolean; }[];

  constructor(private fb: FormBuilder,public route:Router, public service:MainService) { }

  ngOnInit(): void {
    this.addAdmin = this.fb.group({
      'userName': this.fb.control('',Validators.required),
      'name': this.fb.control('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'phoneNo': this.fb.control('',[Validators.required, Validators.pattern(/^[1-9][0-9]{9,13}$/)]),
      'email' :  this.fb.control('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'password' : this.fb.control('', Validators.compose([Validators.required, Validators.pattern(/^(?=^.{8,16}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-])(?!.*\s).*$/)])),
      'confirmPassword' : this.fb.control('', Validators.compose([Validators.required])),
      'lastname' : this.fb.control('',[Validators.required,  Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'status': this.fb.control('',Validators.required),
      'contact':this.fb.control('',[Validators.required,Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'checkArray': this.fb.array([], [Validators.required])
     // 'checkArray': new FormControl('',Validators.required)
    })
  }

  handleInputChange(event)
  {   
      
      var self = this;
      if (event.target.files && event.target.files[0]) {
        var type = event.target.files[0].type;
        if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
          let fileData = event.target.files[0];
          console.log(fileData)
         this.sendFormData1(fileData)
        var reader = new FileReader()
        } else {
          //this.service.showErrorMessage("Select only jpg,jpeg and png file.");
        }
      }

  }

  /** to call form data infoNotification */
sendFormData1(fileData) {
  let formdata = new FormData()
formdata.append('file', fileData);
this.service.showSpinner();

// this.service.postApi('account/upload-file',formdata).subscribe(res => { 
  this.service.post('account/upload-file',formdata).subscribe((res:any)=>{
    
  if(res.status==200){
      this.service.hideSpinner()
      this.userData= res['data'];
      console.log('image', this.userData);
      this.profile = (this.userData) ? this.userData:this.profile;
      this.service.hideSpinner()
      this.service.toasterSucc(res['message'])
 }else{
    this.service.hideSpinner()
    this.service.toasterErr(res.message)
 }
}, error => {
  this.service.hideSpinner();
  // this.service.toasterErr(res.message)
});
 }
  
 ValidateFileUpload(event) {
  this.file = event.target.files;
  if (this.file[0]) {
    this.imageType = this.file[0].type;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl1 = e.target.result;
     // console.log(this.imageUrl1)
    };
    reader.readAsDataURL(this.file[0]);
  }
}
  
  // api of add subadmin
  addSubAdmin(){
    this.service.showSpinner();
    let subAdminDto={
  "userName": this.addAdmin.value.userName,
  "email": this.addAdmin.value.email,
  "firstName": this.addAdmin.value.name,
  "profilePic": this.imageUrl1,
  "lastName": this.addAdmin.value.lastname,
  "password": this.addAdmin.value.password,
  "confirmPassword": this.addAdmin.value.confirmPassword,
  "mobileNumber": this.addAdmin.value.phoneNo,
  //"previlage": this.addAdmin.value.checkArray,
  "userPermissions":this.newArr,
  // "userPermissions": [
  //   {
  //     "isDeleted": true,
  //     "masterPermissionList": {
  //       "isMenu": true,
  //       "masterPermissionListId": 0,
  //       "menuPermission": {
  //         "dataMenu": "string",
  //         "dataSubMenu": "string",
  //         "menuName": "string",
  //         "menuPermissionId": 0,
  //         "parentId": 0,
  //         "path": "string",
  //         "subMenuName": "string"
  //       },
  //       "parentId": 0
  //     },
  //     "userPermissionsId": 0
  //   }
  // ],

     
    }
   // let userid ={ userId : localStorage.getItem('Auth')
 // }
    this.service.post('api/v1/admin/addSubAdmin',subAdminDto).subscribe((res:any)=>{
      this.service.hideSpinner();
      console.log('f', res);
      if(res['responseCode']==200 ){
        //this.subAdminData=res.data
        this.service.toasterSucc(res['responseMessage'])
        this.route.navigate(['/user-management-admin']);
      }
      else if(res['responseCode']==205 || 501){
        this.service.hideSpinner();
        this.service.toasterErr(res.responseMessage)
      }
      
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access');
      } else {
        this.service.toasterErr('Something went wrong');
      }
    })
  }
  onCheckboxChange(e) {
    
    const checkArray: FormArray = this.addAdmin.get('checkArray') as FormArray;

    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
      this.newArr.push(e.target.value);
    } else {
      let i: number = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    console.log('checkbox123',checkArray)
    console.log('NewArray',this.newArr)
  }

  checkboxesDataList = [
    {
      id: 'DASHBOARD',
      label: 'Dashboard',
      isChecked: false
    },
    {
      id: 'USER MANAGEMENT',
      label: 'User Management',
      isChecked: false
    },
    
    // {
    //   id: 'WALLET MANAGEMENT',
    //   label: 'Wallet Management',
    //   isChecked: false
    // },
    {
      id: 'STATIC CONTENT',
      label: 'Static Content',
      isChecked: false
    },
    {
      id: 'KYC MANAGEMENT',
      label: 'KYC Management',
      isChecked: false
    },
    {
      id: 'TOKEN MANAGEMENT',
      label: 'Token Management',
      isChecked: false
    },
    {
      id: 'REDEEM MANAGEMENT',
      label: 'Redeem Management',
      isChecked: false
    },
    {
      id: 'BIDS MANAGEMENT',
      label: 'Bids Management',
      isChecked: false
    },
    // {
    //   id: 'SUBADMIN MANAGEMENT',
    //   label: 'Subadmin Management',
    //   isChecked: false
    // }
  ]
  

}
