<section>
<div class="container1440 mt-5" >
 <main class="middle-content" >
  <div class="content-section">

      <div class="page_title_block pL0 pR0" *ngIf="this.currTab == 'GI'">
          <h1 class="page_title" style="color:#26272d">USER DETAILS</h1>
      </div>
      <div class="page_title_block pL0 pR0" *ngIf="this.currTab == 'Wallet'">
        <h1 class="page_title" style="color:#26272d">USER WALLET DETAILS</h1>
    </div>
    <div class="page_title_block pL0 pR0" *ngIf="this.currTab == 'Transaction'">
        <h1 class="page_title" style="color:#26272d">USER TRADING DETAILS</h1>
    </div>
    <div class="page_title_block pL0 pR0" *ngIf="this.currTab == 'Login'">
        <h1 class="page_title" style="color:#26272d">USER LOGIN HISTORY</h1>
    </div>

      <div class="custom_tabs common-tabs">
          <div class="row mb20 justify-content-center">
              <div class="col-sm-12">
                  <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                              [ngClass]="{'active': currTab=='GI'}" (click)="selectTab('GI')"
                              href="javascript:;">General Information</a>
                      </li>
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                              [ngClass]="{'active': currTab=='Wallet'}" (click)="selectTab('Wallet')"
                              href="javascript:;">Wallet</a>
                      </li>
                      <li class="nav-item flex-fill"> <a class="nav-link active show"
                        [ngClass]="{'active': currTab=='Transaction'}" (click)="selectTab('Transaction')"
                        href="javascript:;">Transaction</a>
                </li>
                <li class="nav-item flex-fill"> <a class="nav-link active show"
                  [ngClass]="{'active': currTab=='Login'}" (click)="selectTab('Login')"
                  href="javascript:;">Login Session Activity</a>
          </li>
                  </ul>
              </div>
          </div>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'GI'" style="margin-bottom: 40px;"> 
          <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding: 6px;max-width: 550px !important;">
              <div class="main-block-innner mb40 mt40" style="margin-bottom: 1px !important;">
                  <div class="add-store-block input-style" style="padding-left: 79px;">
                      <div class="form-group row">
                          <div class="col-md-12" style="text-align: center">
                              <img [src]="userDetail?.imageUrl || 'assets/img/testimonial-img1.jpg'" alt="" style="margin-right: 97px;">
                          </div>
                      </div>
                      <div class="form-group row align-items-baseline" style="margin-top: 30px;">
                          <label class="col-md-5" style="font-size: 16px;font-weight: 600">User Name</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-5">
                              <label style="font-size: 15px;">{{userDetail?.name || 'NA'}}</label>
                          </div>
                      </div><hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-5" style="font-size: 16px;font-weight: 600">Email</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-5">
                              <label  style="font-size: 15px;">{{userDetail?.email || 'NA'}}</label>
                          </div>
                      </div><hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">

                      <div class="form-group row align-items-baseline">
                        <label class="col-md-5" style="font-size: 16px;font-weight: 600">Date Of Birth</label>
                        <div class="col-md-1">:</div>
                        <div class="col-md-5">
                            <label class="user-eml" style="font-size: 15px;">{{userDetail?.dateOfBirth || 'NA'}}</label>
                        </div>
                    </div><hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                    
                      <div class="form-group row align-items-baseline">
                          <label class="col-md-5" style="font-size: 16px;font-weight: 600">Mobile Number</label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-5">
                              <label style="font-size: 15px;">{{userDetail?.countryCode}}{{userDetail?.mobileNumber || 'NA'}}</label>
                          </div>
                      </div>
                      
                      <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                      

                      <div class="form-group row align-items-baseline" >
                          <label class="col-md-5" style="font-size: 16px;font-weight: 600">Address </label>
                          <div class="col-md-1">:</div>
                          <div class="col-md-5">
                              <label  style="font-size: 15px;">{{userDetail?.city}} {{userDetail?.country || 'NA'}}</label>
                          </div>
                      </div>

                      <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                  </div>
                  <div class="head_flt_select input-label" style="margin-left: 1%">
                    <button  routerLink='/user-management' type="submit" class="btn  btn-theme" style="width: 15%;margin-left: 43%;margin-bottom: 3%;">BACK</button>
                </div>
              </div>
          </div>
      </div> 
      
      <!-- tab genral end -->

      <!-- tab wallet  -->
       <div class="tab-pane active show" *ngIf="this.currTab == 'Wallet'">
          <div class="content-section">
              <div class="outer-box">
                  <div class="global-table no-radius p0">
                      <div class="tab-content1">
                          <div class="tab-pane1">
                              <div class="custom_tabs common-tabs">
                                  <div class="row mb20" style="margin-bottom:7px !important">
                                    <div class="col-sm-3 text-right" >
                                        <div class="head_flt_select" style="display: flex; justify-content: center; margin-left: 200%" >
                                            <div class="head_flt_select input-label">
                                                <button type="submit" class="btn  btn-theme" >Balance :{{totalAmountInUSD}} USD</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead> 
                                        <tr class="no_wrap_th">
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Coin Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Wallet Address</th>
                                            <th scope="col">Status</th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                                    <tr *ngFor="let item of  walletArr|paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:walletArr?.length};let i=index">
                                                        <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                                        <td>{{item?.currency}}</td>
                                                        <td>{{item?.balance}}</td>
                                                        <td>{{item?.walletAddress}}</td>
                                                        <td>{{item?.status}}</td>  
                                                    </tr>
 
                                                    <tr *ngIf="tradingDetail?.length==0">
                                                        <td colspan="10" vertical-align="middle">
                                                            <div class="no-record">
                                                                <div class="no-recordin">
                                                                    <H5 style="margin-left: 4%;">No record found</H5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                           
                                      </div>
                                  </div>      
                             </div>
                        </div>
                    </div>
                </div> 
      <!-- tab wallet end-->

      <!-- transaction start -->
      <div class="tab-pane active show" *ngIf="this.currTab == 'Transaction'">
          
            <div class="content-section" *ngIf="viewTransaction!= true">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                              
                              <!-- ******************** This is the filter section start here ********************* -->
                                <div class="user-main-head sec_head_new sec_head_new_after" >
                                    <form [formGroup]="walletForm">
                                        <div class="row">
                                            <div class="col-md-1 pad-0">
                                              <div class="head_flt_select">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center">Type : </span>
                                                    <select formControlName="type"  (change)="typeChange($event.target.value)" [value]="changeType" name="" id="" class="w-10 form-control">
                                                    <option value="">Select</option>
                                                    <option value="BUY">BUY</option>
                                                    <option value="SELL" >SELL</option>
                                                    </select>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-md-1 pad-0-ml">
                                              <div class="head_flt_select">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center" >Currency : </span>
                                                    <select formControlName="currency"  (change)="showCurrency($event.target.value)" name="" id="" class="w-10 form-control">
                                                        <option value="">Select</option>
                                                      <option  *ngFor="let item of coinData" [value]="item?.coinShortName" >{{item?.coinShortName}}</option>
                                                      
                                                    </select>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="col-md-3">
                                              <div class="head_flt_select">
                                                  <div class="head_flt_select input-label input-label1">
                                                      <span class="d-flex align-items-center">From:</span>
                                                      <input type="date" formControlName="startdate" class="form-control"  (change)="onFromChangeDate()" min=""  max={{maxFromDate}} [(ngModel)]="fromDate">
                                                  </div>
                                              </div>
      
                                          </div>
                                          <div class="col-md-3">
      
                                              <!-- <div class="head_flt_select">
                                                  <div class="head_flt_select input-label input-label1">
                                                      <span class="d-flex align-items-center" style="position: relative; top: 28px;">To:</span>
                                                      <input type="date" class="form-control" style="    width: 161px;
                                                      margin-left: 19px;">
                                                  </div>
                                              </div> -->
                                              <div class="head_flt_select">
                                                <div class="head_flt_select input-label input-label1">
                                                    <span class="d-flex align-items-center" >To:</span>
                                                    <input type="date" formControlName="enddate" class="form-control" (change)="onToChangeDate()" min={{minToDate}}  max={{maxToDate}} [(ngModel)]="toDate">
                                                </div>
                                            </div>
      
                                          </div>
                                              <div class="col-md-3">
                                                  <div class="user-form2">
                                                      <div class="row">
                                                          <div class="col-md-12 user-lst">
                                                              <div style="float: right;">
                                                                    <button type="submit" class="btn  btn-theme" (click)="search()">SEARCH</button>
                                                                      <button type="submit" class="btn  ml-1 btn-theme " (click)="reset()"  >RESET</button>
            
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                    </form>
                                </div>
                <!-- ******************** This is the filter section end here ********************* -->
          
                   
                    <div class="row  d-flex justify-content-end w_100" >
                        <label style="margin-top: 8px;font-size: 15px;">Show:</label>
                        <select (change)="showList($event.target.value)" [value]="pageSize" class="form-control" style="height: 40px;width: 80px;margin-right:2%;">
                            <option value="">Select</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <button type="submit" class="btn  btn-theme " (click)="exportPDF()"
                        style="margin-right: 20px;">EXPORT AS PDF </button>

                    <button type="submit" class="btn  btn-theme " (click)="ExportToCsv()"
                        style="margin-right: 20px;">EXPORT AS CSV </button>

                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()"
                        style="margin-right: 20px;">EXPORT AS EXCEL </button>
                                
                        <!-- <button type="submit"   class="btn  btn-theme " (click)="exportAsXLSX()" style="margin-right: 10px;" >EXPORT AS EXCEL </button> -->
                        </div>
               
          
                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id = "pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">S.No</th>
                                                <th scope="col">Transaction ID</th>
                                                <th scope="col">Transaction Type</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Base Coin</th>
                                                <th  scope="col">Executed Currency</th>
                                                <th  scope="col">Date and Time</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
          
                                            </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let data of transactionArr | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:transactionArr?.length};let i=index">
                                              <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                              <td>{{data?.basicTradeHistoryId || 'NA'}}</td>
                                              <td>{{data?.orderType || 'NA'}}</td>
                                              <td>{{data?.baseCoinAmmount || 'NA'}}</td>
                                              <td>{{data?.baseCoinName || 'NA'}}</td>
                                              <td>{{data?.execCoinName || 'NA'}}</td>
                                              <td>{{data?.creationTime | date:'medium' || 'NA'}}</td>
                                              <td class="action_td_btn3" style="cursor: pointer">
                                                <a (click)="viewUserTrading(data?.basicTradeHistoryId)"><i class="fa fa-eye" aria-hidden="true"
                                                  ></i></a>
                                              </td>
        
                                          </tr>

                                        </tbody>
                                        <tbody *ngIf="transactionArr?.length==0">
                                            <tr>
                                                <td colspan="10" vertical-align="middle">
                                                    <div class="no-record">
                                                        <div class="no-recordin">
                                                            <H5 style="margin-left: 4%;">No record found</H5>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                                    <table>
                                      <tr>
                              
                              
                                        <td class="paginate" style="border: 1px solid grey;font-size: 15px;padding: 6px 6px 6px 6px;">Previous</td>
                                        <td class="paginate" style="border: 1px solid grey;font-size: 15px;padding: 6px 6px 6px 6px;">1</td>
                                        <td class="paginate" style="border: 1px solid grey;font-size: 15px;padding: 6px 6px 6px 6px;">2</td>
                                        <td class="paginate" style="border: 1px solid grey;font-size: 15px;padding: 6px 6px 6px 6px;">Next</td>
                                      </tr>
                                    </table>
                                  </div> -->

                                  <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                  *ngIf="transactionArr?.length">
                                  <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                              </div>
                            </div>
                        </div>
                       
                   
          </div>
        </div>
       </div>

        <!-- view transaction -->
     <div *ngIf="viewTransaction == true">
        <div class="tab-pane active show" >
           <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="max-width: 550px !important;" >
          
           <div class="padd_6" style="margin-top: -4%;
           margin-left: 5%;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction ID</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 16px;">{{viewUserObj?.basicTradeHistoryId || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Base Currency</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.execCoinName || 'NA'}}</p>
               </div><hr style="width: 88%;margin-top: -1rem;border-top: 1px solid #d3d3d3;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Executable Currency</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.execCoinName || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Price</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.baseCoinMarketPrice || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Amount(Token)</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 16px;">{{viewUserObj?.baseCoinamount || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Type</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.orderType || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Date and Time</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.creationTime| date:'medium' || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction To</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{viewUserObj?.userEmail || 'NA'}}</p>
               </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
              
           </div>
           <div class="head_flt_select">
               <div class="head_flt_select input-label" style="margin-left: 1%">
                   <button (click)="backTransaction()" type="submit" class="btn  btn-theme" style="width: 15%;margin-left: 43%;">BACK</button>
               </div>
       </div>
      
       </div>
          </div> 
       </div>
         
        <!-- view transaction end-->
     </div>

    
      <!-- transaction end -->


      <!-- tab login session -->
    
      <div class="tab-pane active show" *ngIf="this.currTab == 'Login'" style="margin-bottom:11%;">
        <!-- <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">USER DETAIL LOGIN HISTORY</h1>
        </div> -->
      <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="max-width: 600px !important;">
          <div class="main-block-innner mb40 mt40" style="margin-left: 60px;">

              <div class="add-store-block input-style"> 
          <div class="form-group row align-items-baseline">
            <label class="col-md-4" for="" style="font-size: 16px;font-weight: 600;">Last Login</label>
            <span class="col-md-1">:</span>
            <p class="col-md-6" style="font-size: 15px;">{{loginSessionData?.lastLogin|date:'medium' || 'NA'}}</p>
        </div><hr style="width: 75%;margin-top: -1rem;border-top: 1px solid lightgrey;">

        <!-- <div class="form-group row align-items-baseline">
          <label class="col-md-4 " for="" style="font-size: 16px;font-weight: 600;">Location</label>
          <span class="col-md-1">:</span>
          <p class="col-md-6" style="font-size: 15px;">{{loginSessionData?.location}}</p>
      </div><hr style="width: 75%;margin-top: -1rem;border-top: 1px solid lightgrey;"> -->

      <div class="form-group row align-items-baseline">
            <label class="col-md-4 " for="" style="font-size: 16px;font-weight: 600;">Browser Agent</label>
            <span class="col-md-1">:</span>
            <p class="col-md-6" style="font-size: 15px;">{{loginSessionData?.browserAgent || 'NA'}}</p>
        </div><hr style="width: 75%;margin-top: -1rem;border-top: 1px solid lightgrey;">

      
    <div class="form-group row align-items-baseline">
      <label class="col-md-4" for="" style="font-size: 16px;font-weight: 600;">IP Address</label>
      <span class="col-md-1">:</span>
      <p class="col-md-6" style="font-size: 15px;">{{loginSessionData?.ipAddress || 'NA'}}</p>
  </div><hr style="width: 75%;margin-top: -1rem;border-top: 1px solid lightgrey;">

    <!-- <div class="form-group row align-items-baseline">
      <label class="col-md-4 " for="" style="font-size: 16px;font-weight: 600;" >Last Transaction</label>
      <span class="col-md-1">:</span>
      <p class="col-md-6"  style="font-size: 15px;">{{loginSessionData?.lastTransactionDate|date:'medium'}}</p>
  </div> -->

    </div>
   </div>
  </div>  
 </div> 
    </div>
    </main>
</div>
</section>
    <app-footer></app-footer>
  
  
