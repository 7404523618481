<!-- <section>
    <div class="container1440 mt-5" >
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d">BIDS MANAGEMENT</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="user-main-head sec_head_new sec_head_new_after">
                                <form [formGroup]="userForm">
                              
                                    <div class="row form-group">
                             
                                        <div class="col-md-2">
                                            <label class="d-flex align-items-center">From Date</label>
                                            <input type="date" class="form-control" formControlName="startdate"  placeholder="DD/YY/YY"
                                            (change)="onFromChangeDate()" min=""  max={{maxFromDate}} [(ngModel)]="fromDate">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="d-flex align-items-center">To Date</label>
                                            <input type="date" class="form-control" formControlName="enddate" placeholder="DD/MM/YY"
                                                     (change)="onToChangeDate()" min={{minToDate}}  max={{maxToDate}} [(ngModel)]="toDate">
                                        </div>

                             
                                        <div class=" col-md-3" style="margin-top: 29px;">
                                          
                                            <div class="filter_search " style="width: 115%;">
                                               
                                                <div class="input-group filter_search_group">
                                              
                                                    <input type="text" class="form-control overflow-text"
                                                    placeholder="Search by username "formControlName="searchText">


                                                    <div class="input-group-append">
                                                      
                                                        <button class="btn btn_search_group" type="button"><img
                                                            src="assets/img/icon-search.png" alt="Search"></button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                           
                                        <div class=" col-md-4 " style="margin-top: 33px;">
                                            
                                                <button type="submit" class="btn  btn-theme cus-mr" style="    margin-left: 33%;"
                                                (click)="search()">SEARCH</button>
                                                        <button type="submit" class="btn  btn-theme"
                                                        (click)="resetForm()">RESET </button>


                                          
                                          
                                        </div>
                                        <div class="col-md-12" style="margin-top: 15px; margin-left: 48%;">
                                            <button type="submit" class="btn  btn-theme " (click)="exportPDF()"
                                    style="margin-right: 20px;">EXPORT AS PDF </button>

                                <button type="submit" class="btn  btn-theme " (click)="ExportToCsv()"
                                    style="margin-right: 20px;">EXPORT AS CSV </button>

                                <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()"
                                    style="margin-right: 20px;">EXPORT AS EXCEL </button>
                                            
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div class="row  d-flex justify-content-end w_100">
                          
                            </div>


                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list" id = "pdfcontent">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">S.No</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Date of Birth</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Registration Date</th>
                                            <th scope="col" class="action_td_btn3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of userList| paginate: { 
                                    itemsPerPage: itemsPerPage,
                                    currentPage: pageNumber,
                                    totalItems: total };let i = index ">
                                            <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                            <td>{{item?.name}}</td>
                                            <td>{{item?.email}}</td>
                                            <td>{{item?.dateOfBirth}}</td>
                                            <td>{{item?.mobileNumber}}</td>
                                            <td>{{item?.status}}</td>
                                            <td>{{item?.createdAt | date:'short'}}</td>

                                            <td class="action_td_btn3" style="cursor: pointer">
                                                <a ><i class="fa fa-eye" (click)="userDetails(item?._id)"></i></a>
                                                <a><i class="fa fa-trash" (click)="openModal('DELETE',item?._id)"></i></a>
                                                <a *ngIf="item?.status == 'ACTIVE'" (click)="openModal('BLOCK',item?._id)"><i class="fa fa-ban" style="color: green;"></i></a>
                                                <a *ngIf="item?.status == 'BLOCK'" (click)="openModal('ACTIVE',item?._id)"><i class="fa fa-ban" style="color: red;"></i></a>

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="userList?.length==0">
                                        <tr>
                                            <td colspan="10" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5 style="margin-left: 44%;">No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="userList?.length">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </main>
    
    </div>

</section>

<app-footer></app-footer>
 -->
<section>
    <div class="container1440 mt-5">
        <main class="middle-content">
            <div class="page_title_block">
                <h1 class="page_title" style="color:#26272d;">BIDS MANAGEMENT</h1>
            </div>
            <div class="content-section">
                <div class="outer-box">
                    <div class="global-table no-radius p0">
                        <div class="tab-content1">
                            <div class="tab-pane1">
                                <div class="user-main-head sec_head_new sec_head_new_after">
                                    <form>

                                        <div class="row form-group">

                                            <div class="col-md-3">
                                                <label class="d-flex align-items-center">From Date</label>
                                                <input type="date" name="formdate" class="form-control"
                                                    placeholder="DD/YY/YY" min="">
                                            </div>
                                            <div class="col-md-3">
                                                <label class="d-flex align-items-center">To Date</label>
                                                <input type="date" name="formdate" class="form-control"
                                                    placeholder="DD/MM/YY" min={{minToDate}}>
                                            </div>


                                            <div class=" col-md-3" style="margin-top: 29px;">

                                                <div class="filter_search " style="width: 115%;">

                                                    <div class="input-group filter_search_group">


                                                        <input type="text" class="form-control overflow-text"
                                                            placeholder="Search by bidId">



                                                        <div class="input-group-append">

                                                            <button class="btn btn_search_group" type="button"><img
                                                                    src="assets/img/icon-search.png"
                                                                    alt="Search"></button>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class=" col-md-3 " style="margin-top: 33px;">
                                                <div class=" admin-btn">
                                                    <button type="submit" class="btn  btn-theme cus-mr"
                                                        style="margin-left: 10%;">SEARCH</button>
                                                    <button type="submit" class="btn  btn-theme">RESET </button>


                                                </div>

                                            </div>
                                            <div class="row form-group" style="margin-left: 49%; float:right;">

                                                <div class="col-md-12" style="margin-top: 15px;">
                                                    <button type="submit" class="btn  btn-theme ">EXPORT AS PDF
                                                    </button>

                                                    <button type="submit" class="btn  btn-theme "
                                                        style="margin-left: 10px;">EXPORT AS CSV
                                                    </button>

                                                    <button type="submit" class="btn  btn-theme "
                                                        style="margin-left: 10px;">EXPORT AS
                                                        EXCEL </button>

                                                </div>
                                            </div>
                                            </div>
                                    </form>
                                </div>



                                <div class="table-responsive">
                                    <table class="table table-bordered" aria-describedby="user list" id="pdfcontent">
                                        <thead>
                                            <tr class="no_wrap_th">
                                                <th scope="col">Game Bid ID</th>
                                                <th scope="col">Game Image</th>
                                                <th scope="col">Bid Base Price</th>
                                                <th scope="col">Bid Status</th>
                                                <th scope="col">Bidding Last Time & Date</th>
                                                <th scope="col">Bid Winner Name</th>
                                                <th scope="col">Last Bid Price</th>
                                                <th scope="col">Max Bid price</th>
                                                <th scope="col" class="action_td_btn3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>

                                                <td class="action_td_btn3" style="cursor: pointer"
                                                    routerLink="/viewbids">
                                                    <a><i class="fa fa-eye"></i></a>
                                                    <!--       <a><i class="fa fa-trash" ></i></a>
                                                <a ><i class="fa fa-ban" style="color: green;"></i></a>
                                                <a ><i class="fa fa-ban" style="color: red;"></i></a> -->

                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <!--    <tr>
                                            <td colspan="10" vertical-align="middle">
                                                <div class="no-record">
                                                    <div class="no-recordin">
                                                        <H5 style="margin-left: 44%;">No record found</H5>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                                <!--  <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="userList?.length">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div>

</section>

<app-footer></app-footer>

<!-- delete_modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()"
                                        data-dismiss="modal"
                                        style="min-width:100px;background-color: #13674d;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="min-width: 100px;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Block User </h3>

                        <!-- <h3 *ngIf="userStatus=='ACTIVE'" style="text-align: center; ">Unblock  </h3>
                        <h3  *ngIf="userStatus=='BLOCK'" style="text-align: center; ">Block  </h3> -->
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to block this user?</p>
                                <!-- <p  *ngIf="userStatus=='ACTIVE'" style="font-size: 25px;">Are you sure you want to unblock?</p>
                                <p  *ngIf="userStatus=='BLOCK'" style="font-size: 25px;">Are you sure you want to block?</p> -->
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 "
                                        style="width: 25%;background-color: #13674d;" >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<!-- <div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2" (click)="blockUser()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->