<section>
  <div class="container1440 mt-5">
    <main class="middle-content">
      <div class="content-section">
        <div class="page_title_block pL0 pR0">
          <h1 class="page_title" style="color:#26272d">VIEW TOKEN</h1>
        </div>
        <div class="tab-pane active show">
          <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding-bottom: 1px;min-width: 700px;">
            <div class="main-block-innner mb40 mt40">
              <div class="add-store-block input-style" style="margin-top: -5%;padding-left: 6%;">

                <div class="form-group row">
                  <label class="col-md-4 text" for="" style="font-size: 16px;font-weight: 600;">Token Image</label>
                  <span class="col-md-1" style="font-size: 18px;">:</span>
                  <div class="col-md-3" style="margin-top: -1%;">
                    <img [src]="tokenObj?.tokenImage" alt="no_image">
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-4 text" for="" style="font-size: 16px;font-weight: 600;">Token Name</label>
                  <span class="col-md-1" style="font-size: 18px;">:</span>
                  <div class="col-md-6">
                    <p style="font-size: 16px;margin-left: 13%;">{{tokenObj?.tokenName}}</p>
                  </div>
                </div>
                <!-- <div class="form-group row" >
              <label class="col-md-4 text" for="" style="font-size: 16px;font-weight: 600;" >Contact Address</label>
              <span class="col-md-1" style="font-size: 18px;">:</span>
              <div class="col-md-6" >
              <p style="font-size: 16px;" >{{tokenObj?.address}}</p>
          </div>     
          </div> -->

                <div class="form-group row">
                  <label class="col-md-4 text" for="" style="font-size: 16px;font-weight: 600;">Decimal</label>
                  <span class="col-md-1" style="font-size: 18px;">:</span>
                  <div class="col-md-6">
                    <p style="font-size: 16px;    margin-left: 13%;">{{tokenObj?.decimal}}</p>
                  </div>

                </div>
                <div class="form-group row">
                  <label class="col-md-4 text" for="" style="font-size: 16px;font-weight: 600;">Price</label>
                  <span class="col-md-1" style="font-size: 18px;">:</span>
                  <div class="col-md-6">
                    <p style="font-size: 16px;    margin-left: 13%;">{{tokenObj?.price}}</p>
                  </div>
                </div>



                <div class="text-left mt40 "style="    margin-left: 30%;">
                  <a routerLink="/token-management" class="btn  btn-theme  " style="font-size: 15px;"> BACK</a>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </main>
  </div>
</section>
<!-- <footer style="background: #13674d;">
          <div class="container-fluid">
              <p class="copyright">Copyright @ 2020 All Rights Reserved<strong class="theme-text-color"></strong> 
                  </p>
          </div>
      </footer> -->
<app-footer></app-footer>