<section>
<div class="container1440 mt-5">
<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">TOKEN MANAGEMENT</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Price'}" (click)="selectTab('Price')"
                                href="javascript:;" style="font-size: 14px;">TOKEN MANAGEMENT</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='User'}" (click)="selectTab('User')"
                                href="javascript:;" style="font-size: 14px;">TOKEN TRANSACTIONS</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                          [ngClass]="{'active': currTab=='Admin'}" (click)="selectTab('Admin')"
                          href="javascript:;">Admin Transfer</a>
                  </li> -->
                  
                    </ul>
                </div>
            </div>
          </div>
          <!-- tab for user start -->
          <div class="tab-pane active show"  *ngIf="this.currTab == 'User' && this.viewtx ==true">
            <div class="table-responsive">
                <table class="table table-bordered" aria-describedby="user list" >
                    <thead>
                        <tr class="no_wrap_th"  style="background-color: #13674d;
                        color: white; font-size: 15px;" >
                            <!-- <th scope="col">TxID</th> -->
                            <th scope="col">Token Name</th>
                            <!-- <th scope="col">Transaction By</th> -->
                            <th scope="col">Currency</th>
                            <th scope="col">Transaction Type</th>
                            <th  scope="col">Amount(USD)</th>
                            <th  scope="col">Payment Status</th>
                            <th  scope="col">Transaction Date&Time</th>
                            <th scope="col" class="action_td_btn3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr style="font-size: 15px;" *ngFor= "let data of txnData| paginate: { 
                        itemsPerPage: itemsPerPage,
                        currentPage: pageNumber,
                        totalItems: totalRecords };let i = index">
                       
                          <!-- <td>{{data?.userId}}</td> -->
                          <td>{{data?.coinTitle || '---'}}</td>
                          <!-- <td>{{data?.coinType}}</td> -->
                          <td>{{data?.coinType || '---'}}</td>
                          <td>{{data?.transactionType || '---'}}</td>
                          <td>{{data?.transactionAmount || '---'}}</td>
                          <td >{{data?.transactionStatus || '---'}}</td>
                          <td>{{data?.createdAt | date: 'medium'}}</td>
                         <td><a  ><em class="fa fa-eye" style="padding-left: 29%;" (click)="viewDetails(data?._id)"></em></a></td>
                      </tr>
                     
                    </tbody>
                    <tbody *ngIf="txnData?.length==0">
                        <tr>
                            <td colspan="10" vertical-align="middle">
                                <div class="no-record">
                                    <div class="no-recordin">
                                        <H5 style="margin-left: 44%;">No record found</H5>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
            *ngIf="txnData?.length">
            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
        </div>
            
          </div>
       
          <!-- tab for admin end -->

          <!-- tab price start -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'Price'">
            <!-- <button type="submit"   class="btn  btn-theme" style="float:right; margin-bottom:5px;margin-top:-1%;" [routerLink]="['/add-token']">ADD TOKEN</button> -->
            <div class="table-responsive">
                <table class="table table-bordered" aria-describedby="user list" >
                    <thead>
                        <tr  style="background-color: #13674d;
                        color: white;text-align: center;font-size: 15px;">
                         
                            <th scope="col">Token Name</th>
                            <th scope="col">Token Price</th>
                            <th scope="col">Token Image</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                         </tr>
                    </thead>
                    <tbody>
                         <tr style="font-size: 15px; text-align: center;" *ngFor= "let data of coinData| paginate: { 
                            itemsPerPage: itemsPerPage,
                            currentPage: pageNumber,
                            totalItems: totalRecords };let i = index"  >
                                                                    
                          
                            <td>{{data?.tokenName}}</td>
                            <td>{{data?.price}}</td>
                            <td><img [src]="data?.tokenImage" alt="no_image" style="width: 66px;
                                margin-left: 2%;
                                height: auto;"></td>
                              
                            <td>{{data?.status}}</td>
                            <td class="action_td_btn3" style="cursor: pointer; ">
                            <a  (click)="view(data?._id)"><em class="fa fa-eye" ></em></a>
                            <a  (click)="tokenedit(data?._id)"><i class='fas fa-edit'aria-hidden="true"></i></a>
                        <!-- <a><i class="fa fa-trash" (click)="delete(data?._id)"></i></a> -->
                            </td>
                        </tr>

                       
                    </tbody>
                    <tbody *ngIf="coinData?.length==0">
                        <tr >
                            <td colspan="10" vertical-align="middle">
                                <div class="no-record">
                                    <div class="no-recordin">
                                        <H5 style="margin-left: 44%;">No record found</H5>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
            *ngIf="txnData?.length">
            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
        </div>
        </div>
        
      
     <div *ngIf="viewTransaction == false && this.viewtx== false">
        <div class="tab-pane active show" >
               
           <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="margin-bottom: 6%;"  >
          
           <div class="padd_6" style="margin-top: -4%;
           margin-left: 5%;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction  ID</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 16px;">{{detail?.paymentId || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction By</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{detail?.userEmail || 'NA'}}</p>
               </div><hr style="width: 88%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction Type</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{detail?.transactionType || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Amount</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{detail?.transactionAmount || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <!-- <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Token Value</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 16px;">{{detail?.baseCoinMarketPrice}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;"> -->
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Payment Status</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{detail?.transactionStatus || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
               <div class="form-group row" >
                   <label class="col-md-5 " for="" style="font-size: 16px;font-weight: 600;">Transaction Date & Time</label>
                   <span class="col-md-1">:</span>
                   <p class="col-md-6" style="font-size: 15px;">{{detail?.createdAt | date:'medium' || 'NA'}}</p>
               </div><hr style="width: 85%;margin-top: -1rem;border-top: 1px solid lightgrey;">
             
              
           </div>
           <div class="head_flt_select">
               <div class="head_flt_select input-label" style="margin-left: 1%">
                   <button  type="submit" class="btn  btn-theme" style="width: 15%;margin-left: 43%;" (click)="back()">BACK</button>
               </div>
       </div>
      
       </div>
          </div> 
       </div>
         
        <!-- view transaction end-->
          </div>
</main>
</div>
</section>
<app-footer></app-footer>


<!------------------------ DELETE MODAL ---------------------->

         <div class="modal fade global-modal reset-modal" id="deleteModal">
            <div class="modal-dialog max-WT-500">
                <form class="change_password">
                    <div class="modal-content">
                        <div>
                            <div class="modal-body">
                                <h3 style="text-align: center; font-size: 20px;font-weight: 600; ">Delete Admin </h3>
                                <hr>
                                <button type="button" class="close" data-dismiss="modal"
                                    style="margin-top: -85px;">&times;</button>
                                <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                    <div class="w-100">
                                        <p style="font-size: 18px;">Are you sure you want to delete this Token?</p>
                                        <div>
                                            <button type="submit" class="btn btn-info mr-2 " data-dismiss="modal" style="width: 25%;background-color: #13674d !important;" (click)="deleteToekn()" >Yes</button>
                                            <button type="button" class="btn btn-danger" data-dismiss="modal" style="width: 25%;background-color: #13674d !important;">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div> 