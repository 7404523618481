import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  editForm:FormGroup
  editImage: any;
  userDetail: any;
  date: Date;
  profile: any;
  userData: any;
  imageUrl: any;
  constructor(private router: Router,public service: MainService) { }

  ngOnInit() {
    this.date=new Date()
    
    
     this.myProfile()
    this.editForm = new FormGroup({
      'name': new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'gender': new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z ]*$/i)]),
      'DOB': new FormControl('',[Validators.required]),
      'email': new FormControl('',[Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/i)]),
      'phone': new FormControl('',[Validators.required, Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)]),
      'address': new FormControl('', Validators.required)
    })
  }

  myProfile(){
    var url = 'api/v1/admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['responseCode'] == 200) {
        this.userDetail = res['result']
        this.profile = res['result'].profilePic
        this.editForm.patchValue({
          'name': this.userDetail.name,
          'email': this.userDetail.email,
          'phone': this.userDetail.mobileNumber,
          'address': this.userDetail.address,
          'gender': this.userDetail.gender,
          'DOB': this.userDetail.dateOfBirth
         });
       
      } else {
        this.service.toasterErr(res['responseMessage']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
      this.service.hideSpinner();
    })
  

  }
  private formatDate(date) {
    const d = new Date(date);
    console.log(d)

    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }

  // Api of update profile
  updateProfile() {
    let arr = this.editForm.value.DOB.split('-')
    var apiReq = {
      "address": this.editForm.value.address,
      "dateOfBirth": arr[2]+'/'+arr[1]+'/'+arr[0],
      "name": this.editForm.value.name,
      "mobileNumber": this.editForm.value.phone,
      "gender": this.editForm.value.gender,
      "profilePic": this.profile,
     
    }
    this.service.showSpinner();
    this.service.put('api/v1/admin/editAdmin',apiReq).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res['responseMessage']);
       this.router.navigate(['my-profile'])  
      } 
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }
 /** to call form data infoNotification */

//  handleInputChange(event)
//  {   
     
//      var self = this;
//      if (event.target.files && event.target.files[0]) {
//        var type = event.target.files[0].type;
//        if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
//          let fileData = event.target.files[0];
//         this.sendFormData1(fileData)
//        var reader = new FileReader()
//        } else {
//          //this.service.showErrorMessage("Select only jpg,jpeg and png file.");
//        }
//      }

//  }
sendFormData1(fileData) {
  let formdata = new FormData()
formdata.append('file', fileData);
this.service.showSpinner();

// this.service.postApi('account/upload-file',formdata).subscribe(res => { 
  this.service.post('api/v1/admin/editAdmin',formdata).subscribe((res:any)=>{
    
  if(res.status==200){
      this.service.hideSpinner()
      this.userData= res['result'];
      
      this.profile = (this.userData) ? this.userData:this.profile;
      this.service.hideSpinner()
      this.service.toasterSucc(res['message'])
 }else{
    this.service.hideSpinner()
    this.service.toasterErr(res.message)
 }
}, error => {
  this.service.hideSpinner();
  // this.service.toasterErr(res.message)
});
 }
  
 getToday(): string {
  return new Date().toISOString().split('T')[0]
}

handleInputChange(e) {
  var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  var pattern = /image-*/;
  var reader = new FileReader();
  if (!file.type.match(pattern)) {
    alert('invalid format');
    return;
  }
  reader.onload = this._handleReaderLoaded.bind(this);
  reader.readAsDataURL(file);
}
_handleReaderLoaded(e) {
  let reader = e.target;
  this.profile = reader.result;
  this.userDetail.profilePic = this.profile
 
}

editProfile() {
  let arr = this.editForm.value.DOB.split('-')
  var apiReq = {
    "address": this.editForm.value.address,
    "dateOfBirth": arr[2]+'/'+arr[1]+'/'+arr[0],
    "name": this.editForm.value.name,
    "mobileNumber": this.editForm.value.phone,
    "gender": this.editForm.value.gender,
    "profilePic": this.profile,
   
  }
  this.service.showSpinner();
  this.service.put('api/v1/admin/editAdmin',apiReq).subscribe(res => {
    if (res['responseCode'] == 200) {
      this.service.hideSpinner();
      this.service.toasterSucc(res['responseMessage']);
     this.router.navigate(['my-profile'])  
    } 
  }, err => {
    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
}







}
