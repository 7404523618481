<body class="afterlogin bg-image" style="min-height: 600px;">
    <div class="login-wrapper ">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="forgotPassword">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4 style="color: #13674d;">FORGOT PASSWORD?</h4>
                                </div>
                                <div class="login-box-body">
                                   
                                    <p class="common-paragrph text-center">Enter the account details to reset the password.
                                    </p>
                                    <div class="form-group">
                                        <input type="email" formControlName="email" class="form-control" placeholder="Email address" required /> 
                                        <div
                                            *ngIf="forgotPassword.get('email').hasError('required') && forgotPassword.get('email').touched">
                                            <span class="error">*Please enter email address.</span>
                                        </div>
                                            <span class="error">
                                            <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty"
                                                class="error" padding>*Please enter valid email address.</p>
                                        </span>
                                     </div>
                                    <div class="form-group text-center"> <button type="submit"
                                            class="btn btn-login btn-small width100 font-100 mr-2"
                                           (click)="onForgot()"
                                            style="background: #13674d;" [disabled]="!forgotPassword.valid">SUBMIT</button> <button type="submit"
                                            class="btn btn-login btn-small width100 font-100" [routerLink]="['/login']"
                                            style="background: #13674d;">BACK</button> </div>
                                </div> 
                                <a (click)="onResend()" class="text-center mt20 reset-link">Resend</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <hr />

    <footer class="footer">
        <div class="container-fluid">
            <p class="copyright">Copyright @ 2020 All Rights Reserved  <strong class="theme-text-color"></strong> 
                </p>
        </div>
    </footer>