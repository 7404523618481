import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-kyc-update',
  templateUrl: './kyc-update.component.html',
  styleUrls: ['./kyc-update.component.css']
})
export class KycUpdateComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number=1;
  itemsPerPage:number=20;
  kycList: any;
  userId: any;
  url: string;
  totalRecords: any;
  buttonName: string;
  redeemList: any;

  constructor(public route:Router, public service:MainService) {
    let url = window.location.href
    if(url.includes('kyc-update')){
      this.buttonName = 'kyc-update'
      this.kyc();
    }else if(url.includes('redeem')){

      this.buttonName = 'redeem'
      this.getRedeemList()
    }
   }

  ngOnInit(): void {
   
    this.kycForm = new FormGroup({
      'status': new FormControl('',Validators.required),
      'userName' :  new FormControl('',Validators.required),
    })
  }
  
  // api of kyc listing
  kyc(){
    var url="api/v1/admin/kycList"
    this.service.post(url,'').subscribe((res:any)=>{
      if(res.responseCode==200){
        this.service.toasterSucc(res.responseMessage)
        this.kycList=res.result.docs;
      }else if(res.responseCode == 404){
        this.service.toasterErr(res.responseMessage)
      }
      
    },(err)=>{
      if(err['status']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }

  // Api of search
  search(){
    let data = {
      "kycStatus": this.kycForm.value.status,
      "search": this.kycForm.value.userName
    }
    this.service.showSpinner();
    if(this.kycForm.value.userName || this.kycForm.value.status){
    this.url = "api/v1/admin/kycList";
    }
    this.service.post(this.url,data).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.responseCode==200){
        this.service.toasterSucc(res.responseMessage)
        this.kycList=res.result.docs;
      }
      if(res.responseCode==404){
        this.service.toasterErr(res.responseMessage)
      }
      
    },(err)=>{
      this.service.hideSpinner()
      if(err['responseCode']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })

  }

  // reset
  reset(){
    if(this.kycForm.value.userName || this.kycForm.value.status){
    this.kycForm.reset();
    this.kyc();
    }
  }
  view(id){
    console.log('gg', id);
    this.route.navigate(['/kyc-detail'],{queryParams:{id:id}})  
  }
  //------------------viewRedeem---------------------------//
  viewRedeem(id){
    this.route.navigate(['/redeem-view'],{queryParams:{id:id}})  
  }
  pagination(page){
    this.totalRecords=[]
    this.pageNumber=page;
    this.kyc();
  }
  getRedeemList(){
    var url="api/v1/admin/redeemList"
    this.service.post(url,{}).subscribe((res:any)=>{
      console.log("kyc", res);
      if(res.responseCode==200){
        this.service.toasterSucc(res.responseMessage)
        this.redeemList=res.result.docs;
      }  
    },(err)=>{
      if(err['responseCode']==401){
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    })
  }


}
