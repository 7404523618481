import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  count: any;
 
  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    this.totalActiveUsers()
  }


   //--------------------------------- total active and block user----------------------//
   totalActiveUsers() {
    var url = "api/v1/admin/getCount";
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.count= res
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access');
      } else {
        this.service.toasterErr('Something went wrong');
      }
    })
  }

 
}
