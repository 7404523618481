<section>
<div class="container1440 mt-5">
<main class="middle-content">
  <div class="page_title_block">
    <h1 class="page_title" style="color:#26272d">TRANSACTION</h1>
  </div>
  <div class="content-section">
    <div class="user-main-head sec_head_new sec_head_new_after">
      <form [formGroup]="transactionForm">
        <!-- <div class="row">
            <div class="col-md-2">
              <div class="head_flt_select">
                <div class="head_flt_select input-label input-label1">
                    <span class="d-flex align-items-center" style="position: relative;
                    top: 32px;" >Type : </span>
                    <select formControlName="type" name="" id="" class="w-10 form-control" style="margin-left: 50px;width: 80%;">
                      <option value="">BUY</option>
                      <option >SELL</option>
                    </select>
                </div>
            </div>
            </div>
            <div class="col-md-2">
              <div class="head_flt_select">
                <div class="head_flt_select input-label input-label1">
                    <span class="d-flex align-items-center" style="position: relative; top: 32px;">Currency : </span>
                    <select formControlName="currency" name="" id="" class="w-10 form-control" style="margin-left: 77px;width: 80%;">
                      <option value="">BTC</option>
                      <option>BCH</option>
                      <option>XML</option>
                      <option>BCT</option>
                    </select>
                </div>
            </div>
            </div>
            <div class="col-md-3">
              <div class="head_flt_select">
                  <div class="head_flt_select input-label input-label1">
                      <span class="d-flex align-items-center"
                          style="margin-left: 5px!important;
                          position: relative;
                          padding-left: 43px;
                          top: 34px;">From:</span>

                      <input type="date" class="form-control" style="width: 165px; margin-left: 95px;" >
                  </div>
              </div>

          </div>
          <div class="col-md-2">

              <div class="head_flt_select">
                  <div class="head_flt_select input-label input-label1">
                      <span class="d-flex align-items-center" style="position: relative; top: 34px;">To:</span>
                      <input type="date" class="form-control" style="width: 165px;margin-left: 25px;">
                  </div>
              </div>

          </div>
              <div class="col-md-3">
                  <div class="user-form2">
                      <div class="row">
                          <div class="col-md-12 user-lst">
                              <div style="float: right;">
                                    <button type="submit" class="btn  btn-theme cus-mr" >SEARCH</button>
                                      <button type="submit" class="btn  ml-1 btn-theme cus-mr"
                                        >RESET</button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->
        <div class="row">
          <div class="col-md-2">
            <div class="head_flt_select">
              <div class="head_flt_select input-label input-label1">
                <span class="d-flex align-items-center" style="position: relative;
                    top: 28px;">Type : </span>
                <select formControlName="type"  (change)="typeChange($event.target.value)" [value]="changeType" name="" id="" class="w-10 form-control" style="margin-left: 38px;
                    width: 64%;
                    font-size: 15px;">
                  <option value="">Select</option>
                  <option value="BUY">BUY</option>
                  <option value="SELL" >SELL</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="head_flt_select">
              <div class="head_flt_select input-label input-label1">
                <span class="d-flex align-items-center" style="position: relative; top: 28px;">Currency : </span>
                <select formControlName="currency"  (change)="showCurrency($event.target.value)" name="" id="" class="w-10 form-control" style="    margin-left: 63px;
                    width: 60%;
                    font-size: 15px;">
                <option  *ngFor="let item of coinData" [value]="item?.coinShortName" >{{item?.coinShortName}}</option>

                  
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2 " style="margin-top: 10px;">
            <div class="head_flt_select">
                <div class="head_flt_select input-label input-label1" style="margin-top: 13px;">

                   
                  <span class="d-flex align-items-center" style="margin-left: 19px!important;
                  float: left;
                  margin-top: -17px;">From:</span>
                    <input type="datetime-local" class="form-control" formControlName="startdate" style="width: 161px; margin-top: 2px; " placeholder="DD/YY/YY"
                    (change)="onFromChangeDate()" min=""  max={{maxFromDate}} [(ngModel)]="fromDate">
                </div>
            </div>

        </div>
          <div class="col-md-3">

            <div class="head_flt_select">
                <div class="head_flt_select input-label input-label1" style="margin-top: 23px;">
                    <span class="d-flex align-items-center" style="margin-left: 19px!important;
                float: left;
                margin-top: 8px;">To:</span>
                    <input type="datetime-local" class="form-control" formControlName="enddate" placeholder="DD/MM/YY"
                        style="width: 161px;margin-top: 2px;" (change)="onToChangeDate()" min={{minToDate}}  max={{maxToDate}} [(ngModel)]="toDate">
                </div>
                
            </div>

        </div>
          <div class="col-md-3" style="margin-left: -9px; padding-left: 93px;">
            <div class="user-form2">
              <div class="row">
                <div class=" user-lst">
                  <div style="float: right;">
                    <button type="submit" class="btn  btn-theme" style="position: relative;
                                    top: 4px;
                                   
                                    padding-left: 9px; " (click)="search()">SEARCH</button>
                    <button type="submit" class="btn  ml-1 btn-theme " style="
                                      position: relative;
                                      top: 4px;
                                     " (click)="resetForm()">RESET</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- ******************** This is the filter section end here ********************* -->


    <div class="row  d-flex justify-content-end w_100" style="margin-bottom:1%;margin-right: 2px;">
      <label style="margin-top: 8px;font-size: 15px;">Show:</label>
      <select class="form-control"
        style="height: 40px;width: 80px;margin-right:36px;" (change)="showList($event.target.value)" [value]="pageSize">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
      </select>
      
      <button type="submit" class="btn  btn-theme " (click)="exportPDF()"
      style="margin-right: 20px;">EXPORT AS PDF </button>

  <button type="submit" class="btn  btn-theme " (click)="ExportToCsv()"
      style="margin-right: 20px;">EXPORT AS CSV </button>

  <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()"
      style="margin-right: 20px;">EXPORT AS EXCEL </button>
    </div>


    <div class="table-responsive">
      <table class="table table-bordered" aria-describedby="user list" id = "pdfcontent">
        <thead>
          <tr class="no_wrap_th" style=" background-color: #13674d;font-size: 15px;color: white;" >
            <th scope="col">S.No</th>
            <th scope="col">Transaction ID </th>
            <th scope="col">Transaction Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Base Coin</th>
            <th scope="col">Executed Currency</th>
            <th scope="col">Date and Time</th>
            <th scope="col" class="action_td_btn3">Action</th>

          </tr>
        </thead>
        <tbody>
          <!-- <tr *ngFor="let data of tradingDetail | paginate:{itemsPerPage:20, currentPage:pageNumber, totalItems:tradingDetail?.length};let i=index">
              <td>{{20*(this.pageNumber-1)+i+1}}</td>
              <td>{{data?.basicTradeHistoryId}}</td>
              <td>{{data?.orderType}}</td>
              <td>{{data?.baseCoinAmmount}}</td>
              <td>{{data?.baseCoinName}}</td>
              <td>{{data?.execCoinName}}</td>
              <td>{{data?.creationTime | date}}</td>
              <td class="action_td_btn3" style="cursor: pointer">
                <a (click)="viewUserTrading(data?.basicTradeHistoryId)"><i class="fa fa-eye" aria-hidden="true" routerLink='/view-transaction'
                  ></i></a>
              </td>

          </tr> -->
          <tr *ngFor= "let data of txnData| paginate: { 
            itemsPerPage: itemsPerPage,
            currentPage: pageNumber,
            totalItems: totalRecords };let i = index">
            <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
            <td>{{data?.basicTradeHistoryId}}</td>
            <td >{{data?.orderType}}</td>
            <td>{{data?.baseCoinAmmount}}</td>
            <td>{{data?.baseCoinName}}</td>
            <td>{{data?.execCoinName}}</td>
            <td>{{data?.creationTime | date:'medium'}}</td>
            <td class="action_td_btn3" style="cursor: pointer;">
              <a><i class="fa fa-eye" aria-hidden="true" (click)="view(data?.basicTradeHistoryId)"></i></a>
            </td>

          </tr>
         
         
         
          <!-- <tr *ngIf="tradingDetail?.length==0">
              <td colspan="10" vertical-align="middle">
                  <div class="no-record">
                      <div class="no-recordin">
                          <H5 style="margin-left: 44%;">No record found</H5>
                      </div>
                  </div>
              </td>
          </tr> -->
        </tbody>
        <tbody *ngIf="txnData?.length==0">
          <tr>
              <td colspan="10" vertical-align="middle">
                  <div class="no-record">
                      <div class="no-recordin">
                          <H5 style="margin-left: 44%;">No record found</H5>
                      </div>
                  </div>
              </td>
          </tr>
      </tbody>
      </table>
    </div>
    <!-- <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" >
  <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
</div> -->
<div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
*ngIf="txnData?.length">
<pagination-controls (pageChange)="pagination($event)"></pagination-controls>
</div>
  </div>
</main>
</div>
</section>
<!-- <footer style="background-color: #13674d;">
  <div class="container-fluid">
    <p class="copyright" style="color: white;">Copyright @ 2020 All Rights Reserved<strong
        class="theme-text-color"></strong>
    </p>
  </div>
</footer> -->
<app-footer></app-footer>


