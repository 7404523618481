<main class="middle-content">
                  <div class="page_title_block">
                      <h1 class="page_title" style="color:#26272d">WITHDRAW LIMIT</h1>
                  </div>
                  <div class="content-section">
                      <div class="outer-box">
                          <form [formGroup]="viewWithdrawForm">
                          <div class="global-table no-radius p0">
                              <div class="border  border-dark " style="width: 60%;height: 200px;margin-top: 10%;margin-left: 20%;">
                                  <div style="display: flex;margin-top: 10%;margin-left: 10%;" >
                                      <h5>Withdrawl Limit:</h5>
                                      <input type="text" formControlName="withdraw" style="margin-left: 4%;width: 55%;">
                                  </div>
                                  <div style="margin-top: 8%;margin-left: 20%;">
                                  <button type="submit" class="btn  btn-theme cus-mr"  style="width: 35%;" (click)="updateWithdraw()">save</button>
                                  <button type="submit" class="btn  btn-theme cus-mr"  style="width: 35%;" routerLink="/withdrawl-limit">cancel</button>
                              </div>
                              </div>
                          </div>
                      </form>
                      </div>
                  </div>
              </main>
              
              <footer>
                <div class="container-fluid">
                    <p class="copyright">Alende  <strong class="theme-text-color"></strong> 
                        </p>
                </div>
            </footer>

              <!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Block User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to block this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2"
                                        >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>