<section>
    <div class="container1440 mt-5">

        <main class="middle-content">

    <div class="page_title_block">
        <h1 *ngIf="this.buttonName == 'kyc-update'"class="page_title" style="color:#26272d">KYC MANAGEMENT</h1>
        <h1  *ngIf="this.buttonName == 'redeem'" class="page_title" style="color:#26272d" >REDEEM MANAGEMENT</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        
                        <div class="user-main-head" style="height:83px">
                            <p style="margin-top: -2%;">Filter By</p>
                            <form [formGroup]="kycForm">
                                <div class="row form-group">
                                    <div class="col-md-4"style="padding-top: 1%;">
                                          <label for="" style=" font-size: 15px;">Status:</label>
                                            
                                            
                                                <select formControlName="status" class="form-control" style="position: absolute;
                                                top: 2px;
                                                margin-left: 48px;
                                                width: 202px;">
                                                    <option value="">Select status</option>
                                                    <option value="PENDING">PENDING</option>
                                                    <option value="APPROVED">APPROVED</option>
                                                    <option value="REJECTED">REJECTED</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4 user-lst"  >
                                                <div class="two-btnline">
                                                    <label for="" style="padding-top:4%;font-size: 15px;">Username:</label>
                                                    <div class="text-left" style="margin-left: 2%;margin-top: 2px;" >
                                                        <input type="text" placeholder="Search by username" class="form-control"
                                                        formControlName="userName" > 
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        
                                  

                                    <div class="col-md-2 user-lst"  >
                                            <div  style="margin-top: -3px;
                                             margin-left: 40%;">
                                                <div class="text-left" style="margin-left: 1%" (click)="search()">
                                                    <button type="submit"  class="btn  btn-theme cus-mr" style="margin-top: 5%" >SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 user-lst"  >
                                            <div  style="margin-top: -3px;">
                                                <div class="text-left" style="margin-left: 1%" (click)="reset()">
                                                    <button type="submit"  class="btn  btn-theme cus-mr" style="margin-top: 3%" >RESET</button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                
                            </form>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list" *ngIf="this.buttonName == 'kyc-update'">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Date & Time</th>
                                         <th scope="col">Document ID</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of kycList  | paginate : {itemsPerPage: itemsPerPage,
                                        currentPage: pageNumber,
                                        totalItems: totalRecords };let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.email || '---'}}</td>
                                        <td>{{item?.createdAt | date:'medium'}}</td>
                                        <td>{{item?.idNumber || '---'}}</td>
                                        <td>{{item?.kycStatus || '---'}}</td>
                                        <td class="action_td_btn3" style="cursor: pointer;" >
                                            <a><em class="fa fa-eye"  style="font-size: 20px;" (click)="view(item?._id)"></em></a>
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="kycList?.length==0">
                                    <tr >
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered" aria-describedby="user list" style="margin-bottom: 5%;" *ngIf="this.buttonName == 'redeem'">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Redeem Amount</th>
                                         <th scope="col">Transaction Type</th>
                                         <th scope="col">Redeem Currency</th>
                                         <th scope="col">Coin Title</th>
                                         <th scope="col">Coin Image</th>
                                        <th scope="col">Status</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of redeemList |  paginate : {itemsPerPage: itemsPerPage,
                                        currentPage: pageNumber,
                                        totalItems: totalRecords };let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item?.userId?.name || '---'}}</td>
                                        <td>{{item?.redeemAmount || '---'}}</td>
                                        <td>{{item?.transactionType || '---'}}</td>
                                        <td>{{item?.redeemCurrency || '---'}}</td>
                                        <td>{{item?.coinTitle || '---'}}</td>
                                        <td><img [src]="item?.coinImage"/></td>
                                        <td>{{item?.transactionStatus || '---'}}</td>
                                        <td class="action_td_btn3" style="cursor: pointer;" >
                                            <a><em class="fa fa-eye"  style="font-size: 20px;" (click)="viewRedeem(item?._id)"></em></a>
                                        </td>
                                    </tr>
                                    

                                     <tr *ngIf="kycList?.length==0">
                                         <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr> 
                                </tbody>

                                <tbody *ngIf="redeemList?.length==0">
                                    <tr >
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="kycList?.length" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</section>
<app-footer></app-footer>



