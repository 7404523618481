<section>
    <div class="container1440 mt-5">
        <main class="middle-content" style="margin-bottom: 50px;">
            <div class="page_title_block">
                <h1 class="page_title" style="color:#26272d">STATIC CONTENT MANAGEMENT</h1>
            </div>


            <!-- tab for english static start -->
            <div class="tab-pane active show">
                <div class="content-section">
                    <div class="outer-box">
                        <div class="global-table no-radius p0">
                            <div class="tab-content1">
                                <div class="tab-pane1">
                                    <div class="table-responsive">
                                        <table class="table table-bordered" aria-describedby="static content list">
                                            <thead>
                                                <tr class="no_wrap_th">
                                                    <th scope="col">S.No.</th>
                                                    <th scope="col">Page Name </th>
                                                    <th scope="col" class="action_td_btn3">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of staticData;let i = index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{data?.title | removeUnderscore}}</td>
                                                    <td class="action_td_btn3" style="cursor: pointer;">
                                                        <a *ngIf="data.title == 'About_Us'" [routerLink]="['/AboutUs',data.title]" routerLinkActive="router-link-active"><em class="fa fa-eye"style="font-size: 20px;"></em></a>
                                                        <!-- <a *ngIf="data.title == 'Terms And Condition'"[routerLink]="['/term-and-service',data.title]" routerLinkActive="router-link-active" ><em class="fa fa-eye" style="font-size: 20px;"></em></a> -->
                                                        <a *ngIf="data.title == 'Privacy_Policy'" [routerLink]="['/PrivacyPolicy',data.title]" routerLinkActive="router-link-active"><i class="fa fa-eye" style="font-size: 20px;"></i></a>
                                                        <a *ngIf="data.title == 'Terms & Conditions'" [routerLink]="['/TermsAndCondition',data.title]" routerLinkActive="router-link-active"><i class="fa fa-eye" style="font-size: 20px;"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- tab for spanish static end -->
        </main>
    </div>
</section>
<!-- <footer style="background: #13674d;">
                    <div class="container-fluid">
                        <p class="copyright">Copyright @ 2020 All Rights Reserved<strong class="theme-text-color"></strong> 
                            </p>
                    </div>
                </footer> -->

<app-footer></app-footer>