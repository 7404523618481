<section>
<div class="container1440 mt-5">
                  <main class="middle-content">
                      <div class="page_title_block">
                          <h1 class="page_title" style="color:#26272d">EDIT PROFILE</h1>
                      </div>
                      <div class="content-section" style="margin-top: -1%;">
                          <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding-bottom: 2px;margin-bottom: 6%;">
                                            <div class="text-left" routerLink="/change-password" >
                                          <button type="button"  style="margin-left: 66%;
                                          font-size: 15px; " class="btn btn-lg btn-success ">CHANGE PASSWORD</button>
                                    </div>
                              <div class="main-block-innner mb40 ">
                                                
                                  <div class="add-store-block input-style">
                                        <div class="user-profile">
                                                <div class="image-box">
                                                    <img [src]="userDetail?.profilePic ? userDetail?.profilePic:'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image">
                                                    <label class="upload-label">
                                                          <input type="file" class="hidden" accept=".png, .jpg, .jpeg"
                                                              (change)="handleInputChange($event)" />
                                                          <i class="fas fa-camera" aria-hidden="true"></i>
                                                      </label>
                                                </div>
                                            </div>
                                        <form [formGroup]="editForm" style="padding-left: 30px;">
                                      
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Name </label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="text" formControlName="name" placeholder="Enter your name" class="form-control"
                                              maxlength="30" (keypress)="service.preventSpace($event)"/>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('name').hasError('required') && (editForm.get('name').dirty || editForm.get('name').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('name').hasError('pattern') && editForm.get('name').dirty"
                                                        class="error" padding>*Please enter valid name.</p>
                                                </span>
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Email </label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="text" placeholder="Enter your email" class="form-control"
                                              maxlength="64" (keypress)="service.preventSpace($event)" formControlName="email" readonly/>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('email').hasError('required') && (editForm.get('email').dirty || editForm.get('email').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span>
                                              <span class="error">
                                                  <p *ngIf="editForm.get('email').hasError('pattern') && editForm.get('email').dirty"
                                                      class="error" padding>*Please enter valid Email.</p>
                                              </span>
                                              
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Phone Number</label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="tel" formControlName="phone" placeholder="Enter your phone number" class="form-control"
                                              maxlength="10" >
                                              <span class="error">
                                                    <p *ngIf="editForm.get('phone').hasError('required') && (editForm.get('phone').dirty || editForm.get('phone').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span>
                                              <span class="error">
                                                  <p *ngIf="editForm.get('phone').hasError('pattern') && editForm.get('phone').dirty"
                                                      class="error" padding>*Please enter valid phone number.</p>
                                              </span>
                                              
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Date of Birth </label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="date" id='DOB' formControlName="DOB" placeholder="Enter your DOB" class="form-control" 
                                              [max]="getToday()"/>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('DOB').hasError('required') && (editForm.get('DOB').dirty || editForm.get('DOB').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span>
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Gender </label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="text" formControlName="gender" placeholder="Enter your gender" class="form-control"
                                              minlength="1" maxlength="7" (keypress)="service.preventSpace($event)"/>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('gender').hasError('required') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span> 
                                                <span class="error">
                                                        <p *ngIf="editForm.get('gender').hasError('pattern') && (editForm.get('gender').dirty || editForm.get('gender').touched )"
                                                            class="error" padding>*Please enter valid gender</p>
                                                    </span> 
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5" style="font-size: 16px;font-weight: 600;">Address </label>
                                          <div class="col-md-1" style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <input type="text" formControlName="address" placeholder="Enter your address" class="form-control"
                                              minlength="50" maxlength="100"
                                              (keypress)="service.preventSpace($event)"/>
                                              <span class="error">
                                                    <p *ngIf="editForm.get('address').hasError('required') && (editForm.get('address').dirty || editForm.get('address').touched )"
                                                        class="error" padding>*Field is required</p>
                                                </span>
                                          </div>
                                      </div>
                                      <div class="text-left mt40" style="padding-left: 70px;">
                                          <a class="btn btn-large max-WT-200 font-100 btn-green mr-2" style="font-size: 15px;"
                                              routerLinkActive="router-link-active" (click)="updateProfile()">UPDATE PROFILE</a>
                                          <a href="javascript:void(0)" class="btn btn-large max-WT-200 font-100 btn-green ml5"
                                              [routerLink]="['/my-profile']" style="font-size: 15px;">CANCEL</a>
                                      </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </main>

    </div>   
</section>       
     <app-footer></app-footer>
