import { Component, OnInit } from '@angular/core';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-add-token',
  templateUrl: './add-token.component.html',
  styleUrls: ['./add-token.component.css']
})
export class AddTokenComponent implements OnInit {
  addForm:FormGroup;
  profile: any;
  userData: any;
  constructor(private route:Router , public service : MainService) { }

  ngOnInit() {
    this.addForm= new FormGroup({
      'tokenimage': new FormControl('', Validators.required),
      'token_name': new FormControl('', Validators.required),
      'address': new FormControl('', Validators.required),
      'decimal': new FormControl('', Validators.required),
      'price': new FormControl('', Validators.required),
      
    })
  }
 
  handleInputChange(e) {
    console.log(e)
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.profile = reader.result;
    console.log("profile", this.profile)
  }





  // handleInputChange(event)
  // {   
  //     var self = this;
  //     if (event.target.files && event.target.files[0]) {
  //       var type = event.target.files[0].type;
  //       if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
  //         let fileData = event.target.files[0];
  //        this.sendFormData1(fileData)
  //       var reader = new FileReader()
  //       } else {
  //         //this.service.showErrorMessage("Select only jpg,jpeg and png file.");
  //       }
  //     }

  // }

  
sendFormData1(fileData) {
  let formdata = new FormData()
formdata.append('file', fileData);
formdata.append('file',fileData);
this.service.showSpinner();
  this.service.post('account/upload-file',formdata).subscribe((res:any)=>{
  if(res.status==200){
      this.service.hideSpinner()
      this.userData= res['data'];
      console.log('image', this.userData);
     // this.profile = (this.userData) ? this.userData:this.profile;
      //this.service.hideSpinner()
      this.service.toasterSucc(res['message'])
 }else{
    this.service.hideSpinner()
    this.service.toasterErr(res.message)
 }
}, error => {
  this.service.hideSpinner();
  // this.service.toasterErr(res.message)
});

 }

 addToken(){
   this.service.showSpinner();
  // let formData = new FormData()
  // formData.append('tokenName',JSON.stringify(this.addForm.value.token_name));
  // formData.append('tokenImage',JSON.stringify(this.profile));
  // formData.append('contractAddress',JSON.stringify(this.addForm.value.address));
  // formData.append('decimal',JSON.stringify(this.addForm.value.decimal));
  // formData.append('price',JSON.stringify(this.addForm.value.price));


   let data  = {
    "tokenImage": this.profile,
    "tokenName": this.addForm.value.token_name,
    "contractAddress": this.addForm.value.address,
    "decimal": this.addForm.value.decimal,
    "price": this.addForm.value.price
   }
 this.service.post('api/v1/admin/addToken',data).subscribe((res:any)=>{
   console.log(res)
  this.service.hideSpinner();
  if(res.responseCode==200){
    this.service.toasterSucc(res.responseMessage)
    this.route.navigate(['/token-management']);
  }
}, err => {
  this.service.hideSpinner();
  if (err['status'] == '401') {
    this.service.onLogout();
    this.service.toasterErr('Unauthorized Access');
  } else {
    this.service.toasterErr('Something Went Wrong');
  }
})
  }

}
