<main class="middle-content">
                  <div class="page_title_block">
                      <h1 class="page_title" style="color:#26272d">FIAT MANAGEMENT</h1>
                  </div>
                  <div class="content-section">
                      <div class="outer-box">
                          <div class="global-table no-radius p0">
                              <div class="tab-content1">
                                  <div class="tab-pane1">
                                          
              
                                          <div class="user-main-head sec_head_new sec_head_new_after">
                                                <form [formGroup]="searchForm">
                                            <div class="row form-group">
                                                    
                                                    
                                                        <div class="col-md-2">
                                                          <div class="head_flt_select">
                                                            <div class="head_flt_select input-label input-label1">
                                                                <select name="" id="" class="w-100 form-control" formControlName="select">
                                                                    <option value="">--SELECT--</option>
                                                                    <option value="APPROVED">APPROVED</option>
                                                                    <option value="PENDING">PENDING</option>
                                                                    <option value="CANCEL">CANCEL</option>
                                                                  
                                                                </select>
                                                            </div>
                                                        </div>
                                                        </div>
                
                                                <div class="col-md-2 user-lst" >
                                                    <div class="two-btnline">
                                                        <div class="text-left" >
                                                            <button type="submit" class="btn  btn-theme cus-mr" (click)="search()">Search</button>
                                                        </div>
                
                                                    </div>
                                                </div>
                                                <div class="col-md-2 user-lst" >
                                                    <div class="two-btnline">
                                                        <div class="text-left" >
                                                            <button type="submit" class="btn  btn-theme cus-mr" (click)="reset()">Reset</button>
                                                        </div>
                
                                                    </div>
                                                </div>
                                                
                                            
                                            </div>
                                        </form>
                                        </div>
                                    

              
              
                                      <div class="table-responsive">
                                          <table class="table table-bordered" aria-describedby="user list">
                                              <thead>
                                                  <tr class="no_wrap_th">
                                                      <th scope="col">ID.No.</th>
                                                      <th scope="col">Account Holder Name</th>
                                                      <th scope="col">Bank Name</th>
                                                      <th scope="col">Account No.</th>
                                                      <th scope="col">Amount </th>
                                                      <th scope="col">Email</th>
                                                      <th scope="col">Token Name</th>
                                                      <th scope="col">Status</th>
                                                      <th scope="col">Date</th>
                                                      
                                                      <th scope="col" class="action_td_btn3">Action</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr *ngFor="let data of fiatData | paginate:{itemsPerPage: 20, currentPage: pageNumber,totalItems: fiatData?.length} ; let i=index;">
                                                      <td>{{20*(this.pageNumber-1)+i+1}}</td>
                                                      <td>{{data?.accountHolderName}}</td>
                                                      <td>{{data?.bankName}}</td>
                                                      <td>{{data?.accountNo}}</td>
                                                      <td>{{data?.amount}}</td>
                                                      <td>{{data?.email}}</td>
                                                      <td>{{data?.tokenName}}</td>
                                                      <td>{{data?.status}}</td>
                                                      <td>{{data?.creationTime | date}}</td>
                                                      
              
                                                      <td class="action_td_btn3" style="cursor: pointer">
                                                       <a routerLink='/send-money/{{data?.requestID}}'><em class="fa fa-eye" aria-hidden="true"></em></a>
                                                       <a (click)="approveModal(data?.requestID, data?.tokenName)"><em class="far fa-paper-plane" style="color: green"></em></a>
                                                       <a (click)="cancelModal(data?.requestID)"><em class="fas fa-window-close" style="color: red" aria-hidden="true"></em></a>
                                                       
                                                       </td>
                                                  </tr>

                                                  <tr *ngIf="fiatData?.length==0">
                                                      <td colspan="10" vertical-align="middle">
                                                          <div class="no-record">
                                                              <div class="no-recordin">
                                                                  <H5 style="margin-left: 44%;">No record found</H5>
                                                              </div>
                                                          </div>
                                                      </td>
                                                  </tr>
              
                                                  
                                              </tbody>
                                          </table>
                                      </div>
                                      <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="fiatData?.length>20">
                                                      <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                                      </div>
              
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </main>
              
              <footer>
                  <div class="container-fluid">
                      <p class="copyright">Alende © 2020 <strong class="theme-text-color">Blockchain Crypto</strong> All Rights
                          Reserved.</p>
                  </div>
              </footer>
              
              
              <!-- approve modal start -->
              <div class="modal fade global-modal reset-modal" id="payment">
                <div class="modal-dialog max-WT-500">
                    <div class="modal-content">
                        <div>
                            <div class="modal-body  text-center">
                                <div class="row align-items-center modal_flax_height">
                                    <div class="col">
                                        <div class="box-title mb40 d-inline-block">
                                            <i class="fas fa-power-off off-icon" aria-hidden="true"></i>
                                            <p class="mt40">Are you sure you want to approve?</p>
                                        </div>
                                        <div class="text-center">
                                            <a class="btn btn-blue btn-noYes" (click)="approve()">YES</a> &nbsp; &nbsp; &nbsp;
                                            &nbsp;
                                            <button type="button" class="btn btn-red btn-noYes" data-dismiss="modal">CANCEL</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- approve modal end -->
            <!-- cancel modal start -->
            <div class="modal fade global-modal reset-modal" id="paymentCancel">
                <div class="modal-dialog max-WT-500">
                    <div class="modal-content">
                        <div>
                            <div class="modal-body  text-center">
                                <div class="row align-items-center modal_flax_height">
                                    <div class="col">
                                        <div class="box-title mb40 d-inline-block">
                                            <i class="fas fa-power-off off-icon" aria-hidden="true"></i>
                                            <p class="mt40">Are you sure you want to cancel?</p>
                                        </div>
                                        <div class="text-center">
                                            <a class="btn btn-blue btn-noYes" (click)="cancelRequest()">YES</a> &nbsp; &nbsp; &nbsp;
                                            &nbsp;
                                            <button type="button" class="btn btn-red btn-noYes" data-dismiss="modal">CANCEL</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- cancel modal end -->

            <!-- delete_modal Start -->
            <div class="modal fade global-modal reset-modal" id="deleteModal">
                <div class="modal-dialog max-WT-500">
                    <form class="change_password">
                        <div class="modal-content">
                            <div>
                                <div class="modal-body">
                                    <h3 style="text-align: center; ">Delete Token </h3>
                                    <hr>
                                    <button type="button" class="close" data-dismiss="modal"
                                        style="margin-top: -85px;">&times;</button>
                                    <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                        <div class="w-100">
                                            <p style="font-size: 25px;">Are you sure you want to delete this token?</p>
                                            <div>
                                                <button type="submit" class="btn btn-info mr-2" (click)="deleteUser()">Yes</button>
                                                <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- delete_modal End -->
            
              
              