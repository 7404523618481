
 <main class="middle-content" style="min-height: 520px;">
  <div class="content-section">
      <div class="page_title_block pL0 pR0">
          <h1 class="page_title" >ADD TOKEN</h1>
      </div>
      <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="min-width:850px;">
      
         <form [formGroup]="addForm">
          <div class="form-group row" >
            <label class="col-md-4 text" for="" style="font-size:15px;padding-left: 8%;font-weight: 600;">Token Image</label>
            <span class="col-md-1" style="font-size:18px;">:</span>
            <div class="col-md-6">
                <!-- <img [src]="profile?profile: 'assets/img/dummy.png'" alt=""> -->
                <input type="file" formControlName="tokenimage" (change)="handleInputChange($event)" class="hidden" accept=".png, .jpg, .jpeg"  />
                <span class="error"
                *ngIf="addForm.get('tokenimage').hasError('required') && (addForm.get('tokenimage').dirty || addForm.get('tokenimage').touched )"
                   class="error">
                   <p>
                       *Field is required
                   </p>
           </span>
        </div>
        </div>
        <div class="form-group row" >
            <label class="col-md-4 text" for="" style="font-size:15px;padding-left: 8%;font-weight: 600;">Token Name</label>
            <span class="col-md-1" style="font-size:18px;">:</span>
            <div class="col-md-6">
                <input  type="text" formControlName="token_name" class="form-control" >
                <span class="error"
                *ngIf="addForm.get('token_name').hasError('required') && (addForm.get('token_name').dirty || addForm.get('token_name').touched )"
                   class="error">
                   <p>
                       *Field is required
                   </p>
           </span>
            </div>
          
                  
        </div>

        <div class="form-group row" >
            <label class="col-md-4 text" for="" style="font-size:15px;padding-left: 8%;font-weight: 600;">Contract Address</label>
            <span class="col-md-1" style="font-size:18px;">:</span>
            <div class="col-md-6">
                <input  type="text" formControlName="address" class="form-control" >
                <span class="error"
                *ngIf="addForm.get('address').hasError('required') && (addForm.get('address').dirty || addForm.get('address').touched )"
                   class="error">
                   <p>
                       *Field is required
                   </p>
           </span>
            </div>
            <!-- <div
                 *ngIf="addForm.get('address').hasError('required') && addForm.get('address').touched" style="margin-left: 52%">
                  <span  class="error">*Address is required.</span>
                  </div> -->
                  
        </div>
   
        <div class="form-group row" >
            <label class="col-md-4 text" for="" style="font-size:15px;padding-left: 8%;font-weight: 600;">Decimal</label>
            <span class="col-md-1" style="font-size:15px;">:</span>
            <div class="col-md-6">
            <input  type="text" formControlName="decimal" class="form-control" >
            <span class="error"
            *ngIf="addForm.get('decimal').hasError('required') && (addForm.get('decimal').dirty || addForm.get('decimal').touched )"
               class="error">
               <p>
                   *Field is required
               </p>
       </span>

            </div>
            <!-- <div
                 *ngIf="addForm.get('decimal').hasError('required') && addForm.get('decimal').touched" style="margin-left: 52%">
                  <span  class="error">*Decimal is required.</span>
                  </div> -->
        </div>
        <div class="form-group row" >
            <label class="col-md-4 text" for="" style="font-size:15px;padding-left: 8%;font-weight: 600;">Price</label>
            <span class="col-md-1" style="font-size:15px;">:</span>
            <div class="col-md-6">
            <input  type="text"  formControlName="price" class="form-control" >
            <span class="error"
            *ngIf="addForm.get('price').hasError('required') && (addForm.get('price').dirty || addForm.get('price').touched )"
               class="error">
               <p>
                   *Field is required
               </p>
       </span>
            </div>
            <!-- <div
                 *ngIf="addForm.get('price').hasError('required') && addForm.get('price').touched" style="margin-left: 52%">
                  <span  class="error">*Price is required.</span>
                  </div> -->
        </div>
     </form>
        
                  <div class="row form-group w-100 d-flex justify-content-center" style="margin-top: 3%;">
                  <div class="col-md-3">
                  <button type="submit" class="btn btn-lg btn-success"  (click)="addToken()"  [disabled]="!addForm.valid" style="font-size: 15px;">ADD TOKEN</button>
                  <button type="submit" class="btn btn-lg btn-success" style="float:right; margin-right: -34%;font-size: 15px;" routerLink="/token-management" >CANCEL</button>
                  </div>
                  
                  </div>
                  </div>




      </div>
    </main>
    

    <!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Token </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this token?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2" >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->
<app-footer></app-footer>