import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  tandc:any
  description:string;
  currUrl: any;
  title: any;

  terms:any=[];
  id: any;
  dataa: any=[];
  staticId: any;
  constructor(private active: ActivatedRoute, public service: MainService,  public router:Router) 
  { 

    this.active.params.subscribe(x=>{
      this.id= x['id'];
    })
    // this.active.queryParams.subscribe((params)=>{
    //   this.currUrl=params.tab
    //   console.log('jj', this.currUrl);
      
    // })
  }

  ngOnInit() {
  // this.LanguageData() 
  this.termsAndConditions()
  }


    // Get List Code
    getListCode(){
      this.service.showSpinner();
      this.service.get('static/get-static-page-data?pageKey=TERMS AND CONDITION').subscribe(res=>{
      
        this.service.hideSpinner();
        if(res['status']== 200){
          var data = res['data'];
          this.dataa = res['data'];
          // this.terms = data.filter(x=>(x.staticContentId == this.id))
         
        }
      }, err=>{
      
        this.service.hideSpinner();
        if(err['status']=='401'){
          this.service.onLogout();
          this.service.toasterErr('Unauthorized Access');
        }else{
        this.service.toasterErr('Something Went Wrong');
     }
      })
    }
  
   // Save Abou Us Functionality
   saveTerms(){
    var apiReq = {
      "staticId":this.staticId,
      "pageKey": "Terms & Conditions",
      "description": this.dataa.description
   }
   this.service.showSpinner();
   this.service.post('api/v1/static/editStaticContent',apiReq).subscribe(res=>{
     this.service.hideSpinner();
     if(res['responseCode'] == 200){
      this.service.toasterSucc(res['responseMessage'])
      this.termsAndConditions();
      this.router.navigate(['/static-content-management'])
     }
   }, err=>{
    
     this.service.hideSpinner();
     if(err['status']=='401'){
       this.service.onLogout();
       this.service.toasterErr('Unauthorized Access');
     }else{
     this.service.toasterErr('Something Went Wrong');
  }
   })
   }

  // about language translator
  LanguageData(){
    // if(this.currUrl=='English'){
    //   this.termsAndConditions()
    // }
    // else if(this.currUrl=='German'){
    //   this.termsAndConditionsGerman()
    // }
    // else if(this.currUrl=='Spanish'){
    //   this.termsAndConditionsSpanish()
    // }

  }
  // english
termsAndConditions(){
  let data = {
    "type" :"T&C"
  }
  this.service.post(`api/v1/static/viewStaticContent`,data).subscribe((res:any)=>{
    if(res.responseCode==200){
    this.service.toasterSucc(res.responseMessage)
    this.dataa=res.result
    console.log(this.description)
    console.log(this.title)
    this.title=this.dataa.title
    this.description=this.dataa.description
    this.staticId = this.dataa._id
    }
  },err=>{
  
    this.service.hideSpinner();
    if(err['status']=='401'){
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    }else{
    this.service.toasterErr('Something Went Wrong');
  }
  })
 }

 // german
termsAndConditionsGerman(){
//   this.service.get(`static/get-german-static-page-data-by-page-key?pageKey=Geschäftsbedingungen`).subscribe((res:any)=>{
//     this.tandc=res.data
//     this.title=this.tandc.pageKey
//   this.description=this.tandc.pageData
//  },err=>{
   
//    this.service.hideSpinner();
//    if(err['status']=='401'){
//      this.service.onLogout();
//      this.service.toasterErr('Unauthorized Access');
//    }else{
//    this.service.toasterErr('Something Went Wrong');
// }
//  })
}

// spanish
termsAndConditionsSpanish(){
//   this.service.get(`static/get-spanish-static-page-data-by-page-key?pageKey=términos y condiciones`).subscribe((res:any)=>{
//     this.tandc=res.data
//     this.title=this.tandc.pageKey
//   this.description=this.tandc.pageData
//  },err=>{
   
//    this.service.hideSpinner();
//    if(err['status']=='401'){
//      this.service.onLogout();
//      this.service.toasterErr('Unauthorized Access');
//    }else{
//    this.service.toasterErr('Something Went Wrong');
// }
//  })
}

// update language
updateLanguageData(){
  // if(this.currUrl=='English'){
  //   this.updateTAndC()
  // }
  // else if(this.currUrl=='German'){
  //   this.updateTAndCGerman()
  // }
  // else if(this.currUrl=='Spanish'){
  //   this.updateTAndCSpanish()
  // }
}
  // english
  updateTAndC(){
  //   let request = {
  //     'pageData':this.description,
  //     'pageKey':this.title,
  //   }
  //  this.service.post(`static/update-static-content-data`,request).subscribe((res:any)=>{
  //    if (res.status=200) {
  //      this.service.toasterSucc(res.message)
  //      this.router.navigate(['/static-content-management'])
  //    }
  //   },err=>{
   
  //     this.service.hideSpinner();
  //     if(err['status']=='401'){
  //       this.service.onLogout();
  //       this.service.toasterErr('Unauthorized Access');
  //     }else{
  //     this.service.toasterErr('Something Went Wrong');
  //  }
  //   })
 }

 // german
 updateTAndCGerman(){
//   let request = {
//     'pageData':this.description,
//     'pageKey':this.title,
//   }
//  this.service.post(`static/update-german-static-content-data`,request).subscribe((res:any)=>{
//    if (res.status=200) {
//      this.service.toasterSucc(res.message)
//      this.router.navigate(['/static-content-management'])
//    }
//   },err=>{
 
//     this.service.hideSpinner();
//     if(err['status']=='401'){
//       this.service.onLogout();
//       this.service.toasterErr('Unauthorized Access');
//     }else{
//     this.service.toasterErr('Something Went Wrong');
//  }
//   })
}

// spanish
updateTAndCSpanish(){
//   let request = {
//     'pageData':this.description,
//     'pageKey':this.title,
//   }
//  this.service.post(`static/update-spanish-static-content-data`,request).subscribe((res:any)=>{
//    if (res.status=200) {
//      this.service.toasterSucc(res.message)
//      this.router.navigate(['/static-content-management'])
//    }
//   },err=>{
 
//     this.service.hideSpinner();
//     if(err['status']=='401'){
//       this.service.onLogout();
//       this.service.toasterErr('Unauthorized Access');
//     }else{
//     this.service.toasterErr('Something Went Wrong');
//  }
//   })
 }
}
