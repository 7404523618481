<div class="wrapper" *ngIf="isLoggedIn">
    <!-- top header -->
    <header class="header">
        <nav class="navbar clearfix">
            <div class="logo-box">
                <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active" *ngIf="tag1">
                    <img id="mysidebar" src="assets/img/paycash.png" style="color:#2d3498;height: 59px; " alt="logo" class="lg-logo">
                </a>
                <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="router-link-active" *ngIf="!tag1">
                    <img id="mysidebar" src="assets/img/pay.png" style="color:#2d3498;height: 50px; " alt="logo">
                </a>
            </div>
            <!-- <div class="logo-box" *ngIf="!tag1">
            <a class="navbar-brand logo" [routerLink]="['/dashboard']" routerLinkActive="active">
                <img  id="mysidebar" src="assets/img/pay.png" style="color:#2d3498;height: 59px; " alt="logo" >
            </a>
        </div> -->

            <div class="header-right-part">
                <button class="btn btn-toggle" type="button" (click)="head()">
                    <i class="fas fa-bars" aria-hidden="true"></i>
                </button>
                <button class="btn btn-outline-secondary btn-mobsearch" type="button">
                    <i class="fas fa-search" aria-hidden="true"></i></button>
            </div>
        </nav>
    </header>



    <!-- end top header -->

    <!--------------------------------------------------------------- ADMIN -------------------------------------------------------------------------->
    <aside *ngIf="!flag" class="sidebar" aria-labelledby="sidebar for admin">
        <div class="sidebar-scroller">
            <div id="accordion" class="sidebar-menu">
                <div class="user-panel clearfix">
                    <div class="user-profile" [routerLink]="['/my-profile']">
                        <div class="image-box" style="margin-top: 4%;">
                            <img [src]="profile?profile: 'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image">
                        </div>
                        <div style="margin-left: 36%">
                            <a>
                                <h6 style="font-weight: 600;margin-bottom: 0rem;">Welcome</h6>
                                <h3 style="left: -11px;
                                            font-weight: 600;
                                            padding-left: -7px;
                                            position: relative;">{{userDetail?.firstName}}</h3>
                                <!-- <h3 style="left: -11px;
                                            font-weight: 600;
                                            padding-left: -7px;
                                            position: relative;">Rakesh</h3> -->

                            </a>
                        </div>
                    </div>

                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'dashboard'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="side_menu" [routerLink]="['/dashboard']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/dashboard-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Dashboard</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'user-management' || currUrl == 'wallet-details' || currUrl == 'user-details'}">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/user-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user-manage.png" alt="icon" />
                            </span>
                            <span class="side_page_name">User Management</span>
                        </a>
                    </div>
                </div>
                <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'all-user-trader' }">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/all-user-trader']"
                            routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">All User Trades</span>
                        </a>
                    </div>
                </div> -->
                <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'hot-wallet-management'|| currUrl == 'user-details' || currUrl == 'hot-cold-wallet-management' || currUrl=='view-hot-cold-wallet-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/user-details']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name"> Wallet Management</span>
                        </a>
                    </div>
                </div> -->
                <div class="menu-box" [ngClass]="{'active':currUrl == 'user-management-admin' || currUrl == 'admin-detail' || currUrl == 'add-administator' || currUrl == 'admin-detail'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/user-management-admin']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Sub Admin Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'kyc-update' || currUrl == 'kyc-detail'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/kyc-update']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">KYC Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'token-management' || currUrl == 'add-token' || currUrl == 'edit-token' || currUrl == 'view-token'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/token-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/wallet.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Token Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'redeem' || currUrl == 'redeem-view'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/redeem']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/copy-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Redeem Management</span>
                        </a>
                    </div>
                </div>

                <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'transaction-management' || currUrl == 'view-transaction'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/transaction-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/copy-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Transaction Management</span>
                        </a>
                    </div>
                </div> -->
                <div class="menu-box" [ngClass]="{'active':currUrl == 'bids-management' || currUrl == 'viewbids'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/bids-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/copy-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Bids Management</span>
                        </a>
                    </div>
                </div>
                <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'ticket-management' || currUrl == 'view-ticket' || currUrl == 'reply-ticket'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/ticket-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/fees (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Ticket Management</span>
                        </a>
                    </div>
                </div> -->
                <!-- <div class="menu-box" [ngClass]="{'active':currUrl == 'manage-fee' || currUrl == 'standard-trading-fee' || currUrl == 'min-trading-fee' || 
                currUrl == 'min-withdrawl-amount' || currUrl == 'update-withdrawl-amount'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/manage-fee']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/fees (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fee Management</span>
                        </a>
                    </div>
                </div> -->
                <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'announcement-management' || currUrl == 'add-announcement' || currUrl=='edit-announcement' || currUrl=='view-announcement'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/announcement-management']"
                            routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name">Announcement Management</span>
                        </a>
                    </div>
                </div> -->

                <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'fiat' || currUrl == 'send-money' || currUrl == 'privacy-policy' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/fiat']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Fiat Management</span>
                        </a>
                    </div>
                </div> -->



                <div class="menu-box" [ngClass]="{'active':currUrl == 'static-content-management' || currUrl == 'AboutUs' || currUrl == 'PrivacyPolicy' ||  currUrl == 'TermsAndCondition'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/static-content-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Static Content Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" [ngClass]="{'active':currUrl == 'faq-management' || currUrl == 'add-faq' || currUrl == 'edit-faq' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/faq-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">FAQ's Management</span>
                        </a>
                    </div>
                </div>

                <!-- <div class="menu-box"
                    [ngClass]="{'active':currUrl == 'setting' || currUrl == 'my-profile' || currUrl == 'edit-profile' || currUrl == 'taker-maker-fee' || currUrl == 'view-limit' || currUrl == 'withdrawl-fee' || currUrl == 'withdrawl-limit' || currUrl == 'change-password'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/setting']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/setting-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Settings</span>
                        </a>
                    </div>
                </div> -->

                <div class="menu-box">
                    <div class="card-header clearfix">
                        <a class="side_menu" href="#signout_modal" data-toggle="modal">
                            <span class="side_menu_icon">
                                <img src="assets/img/logout.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </aside>


    <!--------------------------------------------------------------- SUB ADMIN -------------------------------------------------------------------------->
    <aside *ngIf="flag" class="sidebar" aria-labelledby="sidebar for subadmin">
        <div class="sidebar-scroller">
            <div id="accordion" class="sidebar-menu">
                <div class="user-panel clearfix">
                    <div class="user-profile" [routerLink]="['/my-profile']">
                        <div class="image-box">
                            <img [src]="profile?profile: 'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image">
                        </div>

                        <div style="margin-left: 36%">
                            <a>
                                <h6 style="font-weight: 600;margin-bottom: 0rem;">Welcome</h6>
                                <!-- <h4>{{userDetail?.firstName}}</h4> -->
                                <h3 style="left: -11px;
                                        font-weight: 600;
                                        padding-left: -7px;
                                        position: relative;">{{userDetail?.firstName}}</h3>

                            </a>
                        </div>
                    </div>

                </div>
                <div class="menu-box" *ngIf="staticdasmgmt==true" [ngClass]="{'active':currUrl == 'dashboard'}">
                    <div class="card-header clearfix" id="headingOne">
                        <a class="side_menu" [routerLink]="['/dashboard']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/dashboard-icon.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Dashboard</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="staticusermgmt==true" [ngClass]="{'active':currUrl == 'user-management' || currUrl == 'user-details'|| currUrl == 'wallet-details'}">
                    <div class="card-header clearfix" id="headingTwo">
                        <a class="side_menu" [routerLink]="['/user-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user-manage.png" alt="icon" />
                            </span>
                            <span class="side_page_name">User Management</span>
                        </a>
                    </div>
                </div>
                <!-- <div class="menu-box" *ngIf="staticwalletmgmt==true"
                    [ngClass]="{'active':currUrl == 'hot-wallet-management'|| currUrl == 'user-details' || currUrl == 'hot-cold-wallet-management' || currUrl=='view-hot-cold-wallet-management'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/user-details']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/stable (1).png" alt="icon" />
                            </span>
                            <span class="side_page_name"> Wallet Management</span>
                        </a>
                    </div>
                </div> -->

                <div class="menu-box" *ngIf="statickycmgmt==true" [ngClass]="{'active':currUrl == 'kyc-detail' || currUrl == 'kyc-update'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/kyc-update']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">KYC Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="staticsubmgmt==true" [ngClass]="{'active':currUrl == 'sub-admin-management' || currUrl == 'add-subadmin' || currUrl == 'view-subadmin' || currUrl == 'admin-detail?id'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/sub-admin-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Sub Admin Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="statictokmgmt==true" [ngClass]="{'active':currUrl == 'token-management' || currUrl == 'add-token' || currUrl == 'edit-token' || currUrl == 'view-token'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/token-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/user.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Token Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="statictransmgmt==true" [ngClass]="{'active':currUrl == 'transaction-management' || currUrl == 'view-transaction'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/transaction-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/copy-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Transaction Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box" *ngIf="bidManagement==true" [ngClass]="{'active':currUrl == 'bids-management' || currUrl == 'viewbids'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/bids-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/copy-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Bids Management</span>
                        </a>
                    </div>
                </div>




                <div class="menu-box" *ngIf="staticflag==true" [ngClass]="{'active':currUrl == 'static-content-management' || currUrl == 'about-us' || currUrl == 'privacy-policy' ||  currUrl == 'terms-and-conditions'}">
                    <div class="card-header clearfix" id="headingThree">
                        <a class="side_menu" [routerLink]="['/static-content-management']" routerLinkActive="active">
                            <span class="side_menu_icon">
                                <img src="assets/img/static-content.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Static Content Management</span>
                        </a>
                    </div>
                </div>
                <div class="menu-box">
                    <div class="card-header clearfix">
                        <a class="side_menu" href="#signout_modal" data-toggle="modal">
                            <span class="side_menu_icon">
                                <img src="assets/img/logout.png" alt="icon" />
                            </span>
                            <span class="side_page_name">Logout</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </aside>

</div>

<!-- Logout Modal   -->
<div class="modal fade global-modal reset-modal" id="signout_modal">
    <div class="modal-dialog max-WT-500">
        <div class="modal-content">
            <div>
                <div class="modal-body  text-center">
                    <div class="row align-items-center modal_flax_height">
                        <div class="col">
                            <div class="box-title mb40 d-inline-block">
                                <i class="fas fa-power-off off-icon" aria-hidden="true"></i>
                                <p class="mt40">Are you sure you want to logout?</p>
                            </div>
                            <div class="text-center">
                                <a class="btn btn-blue btn-noYes" (click)="onLogout()" style="background: #13674d !important;">YES</a> &nbsp; &nbsp; &nbsp; &nbsp;
                                <button type="button" class="btn  btn-noYes" data-dismiss="modal" style="background: #13674d !important;">CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>