<section>
<div class="container1440 mt-5">
<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">SUB ADMIN MANAGEMENT</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head">
                            <p
                                style="margin-top: -2%;font-size: 15px;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p>
                            <form [formGroup]="adminForm">

                                <div class="row form-group">
                               
                                    <div class="col-md-3">
                                        <label class="d-flex align-items-center">From Date</label>
                                        <input type="date" name="formdate" class="form-control" formControlName="startdate"
                                        placeholder="DD/YY/YY"  (change)="onFromChangeDate()" min=""  max={{maxFromDate}} [(ngModel)]="fromDate">
                                    </div>
                                    <div class="col-md-3">
                                        <label class="d-flex align-items-center">To Date</label>
                                        <input type="date" name="formdate" class="form-control"
                                        formControlName="enddate" placeholder="DD/MM/YY" (change)="onToChangeDate()"   max={{maxToDate}} [(ngModel)]="toDate" >
                                    </div>


                               
                                    <div class=" col-md-3" style="margin-top: 29px;">
                                          
                                        <div class="serch-boxuser ">
                                           
                                            <div class="input-group filter_search_group" style="width: 116%">
                                          
                                                <input type="text" class="form-control overflow-text"
                                                placeholder="Search by email"   formControlName="searchText">


                                                <div class="input-group-append">
                                                  
                                                           <button class="btn btn_search_group" type="button" style="margin-top: -4%;"><img
                                                            src="assets/img/icon-search.png"alt="Search"></button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                         
                                    <div class=" col-md-3 " style="margin-top: 33px;">
                                        <div class=" admin-btn">
                                            <button type="submit" class="btn  btn-theme cus-mr" style="margin-left: 1%;"
                                                    (click)="search()">SEARCH</button>
                                                    <button type="submit" class="btn  btn-theme" style="margin-left: 17%;"
                                                        (click)="resetForm()">RESET </button>


                                        </div>
                                      
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div class="row form-group"
                            style="display: flex; justify-content: flex-end;margin-top: -11px; ">
                           
                            <a routerLink="/add-administator" style="margin-right: 18px;"><i class="fa fa-user-plus"
                                    aria-hidden="true"
                                    style=" color: black;margin-left: -113%;font-size: 25px;margin-top: 8px;"></i></a>

                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Last Logged In</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 15px;">
                                    <tr *ngFor="let item of SubAdminArr  | paginate : {itemsPerPage: itemsPerPage,currentPage: pageNumber,
                                            totalItems: total };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td >{{item?.firstName}}</td>
                                        <td >{{item?.email}}</td>
                                        <td >{{item?.userType}}</td>
                                        <td >{{item?.mobileNumber}}</td>
                                        <td  *ngIf="item?.status == 'ACTIVE'" style="color:green">{{item?.status}}</td>
                                        <td  *ngIf="item?.status == 'BLOCK'" style="color:red">{{item?.status}}</td>
                                        <td >{{item?.updatedAt | date:'short'}}</td>
                                        <td  class="action_td_btn3" style="cursor: pointer;"> <a
                                                (click)="viewAdmin(item?._id)"><em class="fa fa-eye"></em></a>
                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="delete(item?._id)"></i></a>
                                            <a *ngIf="item?.status == 'ACTIVE'"><i class="fa fa-ban"  aria-hidden="true"
                                                    (click)="block('BLOCK',item?._id)"  style="color: green;"></i></a>
                                            <a *ngIf="item?.status == 'BLOCK'" ><i class="fa fa-ban"  aria-hidden="true"
                                                    (click)="block('ACTIVE',item?._id)" style="color: red"></i></a></td>
                                    </tr>
                                 </tbody>
                                 <tbody *ngIf="SubAdminArr?.length==0">
                                    <tr >
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="SubAdminArr?.length" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-section" *ngIf="false" >
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head">
                            <p
                                style="margin-top: -2%;font-size: 15px;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p>
                            <form [formGroup]="adminForm">

                                <div class="row form-group">
                               
                                    <div class="col-md-3">
                                        <label class="d-flex align-items-center">From Date</label>
                                        <input type="date" name="formdate" class="form-control" formControlName="startdate"
                                        placeholder="DD/YY/YY"  (change)="onFromChangeDate()" min=""  max={{maxFromDate}} [(ngModel)]="fromDate">
                                    </div>
                                    <div class="col-md-3">
                                        <label class="d-flex align-items-center">To Date</label>
                                        <input type="date" name="formdate" class="form-control"
                                        formControlName="enddate" placeholder="DD/MM/YY" (change)="onToChangeDate()"   max={{maxToDate}} [(ngModel)]="toDate" >
                                    </div>


                               
                                    <div class=" col-md-3" style="margin-top: 29px;">
                                          
                                        <div class="serch-boxuser ">
                                           
                                            <div class="input-group filter_search_group" style="width: 116%">
                                          
                                                <input type="text" class="form-control overflow-text"
                                                placeholder="Search by email"   formControlName="searchText">


                                                <div class="input-group-append">
                                                  
                                                           <button class="btn btn_search_group" type="button" style="margin-top: -4%;"><img
                                                            src="assets/img/icon-search.png"alt="Search"></button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                         
                                    <div class=" col-md-3 " style="margin-top: 33px;">
                                        <div class=" admin-btn">
                                            <button type="submit" class="btn  btn-theme cus-mr" style="margin-left: 1%;"
                                                    (click)="search()">SEARCH</button>
                                                    <button type="submit" class="btn  btn-theme" style="margin-left: 17%;"
                                                        (click)="resetForm()">RESET </button>


                                        </div>
                                      
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div class="row form-group"
                            style="display: flex; justify-content: flex-end;margin-top: -11px; ">
                           
                            <a routerLink="/add-administator" style="margin-right: 18px;"><i class="fa fa-user-plus"
                                    aria-hidden="true"
                                    style=" color: black;margin-left: -113%;font-size: 25px;margin-top: 8px;"></i></a>

                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Mobile Number</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Last Logged In</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 15px;">
                                    <tr *ngFor="let item of SubAdminArr  | paginate : {itemsPerPage: itemsPerPage,currentPage: pageNumber,
                                            totalItems: total };let i = index">
                                        <td>{{itemsPerPage*(this.pageNumber-1)+i+1}}</td>
                                        <td >{{item?.firstName}}</td>
                                        <td >{{item?.email}}</td>
                                        <td >{{item?.userType}}</td>
                                        <td >{{item?.mobileNumber}}</td>
                                        <td  *ngIf="item?.status == 'ACTIVE'" style="color:green">{{item?.status}}</td>
                                        <td  *ngIf="item?.status == 'BLOCK'" style="color:red">{{item?.status}}</td>
                                        <td >{{item?.updatedAt | date:'short'}}</td>
                                        <td  class="action_td_btn3" style="cursor: pointer;"> <a
                                                (click)="viewAdmin(item?._id)"><em class="fa fa-eye"></em></a>
                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="delete(item?._id)"></i></a>
                                            <a *ngIf="item?.status == 'ACTIVE'"><i class="fa fa-ban"  aria-hidden="true"
                                                    (click)="block('BLOCK',item?._id)"  style="color: green;"></i></a>
                                            <a *ngIf="item?.status == 'BLOCK'" ><i class="fa fa-ban"  aria-hidden="true"
                                                    (click)="block('ACTIVE',item?._id)" style="color: red"></i></a></td>
                                    </tr>
                                 </tbody>
                                 <tbody *ngIf="SubAdminArr?.length==0">
                                    <tr >
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 44%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end" *ngIf="SubAdminArr?.length" >
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
</div>
</section>


<app-footer></app-footer>

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-size: 20px;">Delete Sub Admin </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;">Are you sure you want to delete this Sub Admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " (click)="deleteUser()"
                                        style="width: 25%;background-color: #13674d !important;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;background-color: #13674d !important;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->

<!-- block_modal Start -->
<div class="modal fade global-modal reset-modal" id="block">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; font-size: 20px;" *ngIf="userStatus == 'BLOCK'">Block Sub Admin</h3>
                        <h3 style="text-align: center; font-size: 20px;" *ngIf="userStatus == 'ACTIVE'">Unblock Sub Admin</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;"  *ngIf="userStatus == 'BLOCK'">Are you sure you want to block this Sub Admin?</p>
                                <p  style="font-size: 25px;"  *ngIf="userStatus =='ACTIVE'">Are you sure you want to Unblock this Sub Admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " (click)="blockUser()"
                                        style="width: 25%;background-color: #13674d !important;">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;background-color: #13674d !important;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>