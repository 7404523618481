import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $:any

@Component({
  selector: 'app-kyc-detail',
  templateUrl: './kyc-detail.component.html',
  styleUrls: ['./kyc-detail.component.css']
})
export class KycDetailComponent implements OnInit {
  modalForm:FormGroup
  userId: any;
  viewData: any={};
  kycId: any;
  kycStatus: any;
  redeemId: any;
  buttonName: string;
  redeemObj: any;
 

  constructor(public router:Router, public service:MainService, public active:ActivatedRoute) {
    this.active.queryParams.subscribe((params)=>{
      this.kycId=params.id 
      this.redeemId = params.id     
    })
    let url = window.location.href
    if(url.includes('kyc-detail')){
      this.buttonName = 'kyc-detail'
      this.viewKyc();
    }else if(url.includes('redeem-view')){
      this.buttonName = 'redeem-view'
      this.viewRedeem()
    }
  }

  ngOnInit(): void {
    this.modalForm= new FormGroup({
      'reason': new FormControl('', Validators.required)
    })
  }

  // Api of view kyc
  viewKyc(){
    var url = 'api/v1/admin/viewKyc/'+(this.kycId);
    this.service.get(url).subscribe((res:any)=>{
      console.log('view',res);
      if(res.responseCode==200 || 404){
        this.service.toasterSucc(res.responseMessage)
        this.viewData=res.result;
        this.kycStatus=res.result.kycStatus
        this.userId=res.result.userId;
      }
    },(err)=>{
      if(err['responseCode']==401){
        this.service.toasterErr(err.responseMessage)
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    }) 
      
  }
  approved(){
    if(this.kycStatus == 'APPROVED'){
      this.service.toasterInfo('Already kyc APPROVED');
      
    }
    if(this.kycStatus == 'PENDING'){
      console.log('appo')
      $('#approveModal').modal('show')
    }
   }

   rejected(){
    if(this.kycStatus=='REJECTED'){
      this.service.toasterInfo('Already kyc rejected');
      
    }
    else{
    $('#rejectModal').modal('show')
  }
  }

  approveKyc(){
    this.service.showSpinner();
    let data = {
      "userId": this.userId,
      "kycId": this.kycId,
    }
    this.service.post('api/v1/admin/approveKyc',data).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.responseCode == 200) {
        this.service.toasterSucc(res.responseMessage)
        $('#approveModal').modal('hide');
        this.router.navigate(['/kyc-update'])
      }
      else {
        this.service.hideSpinner();
      }
    },(error)=>{
      this.service.hideSpinner();
    })
  }

  rejectKyc() {
    this.service.showSpinner();
    let data = {
      "userId": this.userId,
      "kycId": this.kycId,
      "reason": this.modalForm.value.reason,
    }
    this.service.post('api/v1/admin/rejectKyc',data).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.responseCode == 200) {
        this.service.toasterSucc(res.responseMessage)
        $('#rejectModal').modal('hide')
        this.router.navigate(['/kyc-update'])
      }
      else {
        this.service.hideSpinner();
      }
    },(error)=>{
      this.service.hideSpinner();
    })
  }
  //------------------------view Redeem -------------------------//
  viewRedeem(){
    var url = 'api/v1/admin/viewRedeemTransaction/'+(this.redeemId);
    this.service.get(url).subscribe((res:any)=>{
      console.log('view',res);
      if(res.responseCode==200 || 404){
        this.service.toasterSucc(res.responseMessage)
        this.redeemObj=res.result;
        this.kycStatus=res.result.transactionStatus
        this.userId=res.result.userId;
      }
    },(err)=>{
      if(err['responseCode']==401){
        this.service.toasterErr(err.responseMessage)
      }
      else{
        this.service.toasterErr('Something Went Wrong');
     }
    }) 
  }

  actionOnReedem(action,item) {
    let url = 'api/v1/admin/approveRejectRedeemToken';
    let req  = {
      'transactionId': item._id,
      'approvalStatus': action
    }
    this.service.post(url,req).subscribe((res:any)=>{
      if(res.responseCode == 200 || 400){
        this.service.toasterSucc(res.responseMessage)
      }
    })
  }
}
