import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  userDetail: any;
  profile: any;

  constructor(public service: MainService) { }

  ngOnInit() {
    this.myProfile();
  }

  // Api of my-profile
  
  myProfile(){
    var url = 'api/v1/admin/getProfile';
    this.service.showSpinner();
    this.service.get(url).subscribe(res=>{
      this.service.hideSpinner();
      if(res['responseCode']== 200){  
        this.service.toasterSucc(res['responseMessage']);    
       this.userDetail = res['result']
      }
    },err=>{
    
      this.service.hideSpinner();
      if(err['responseCode']=='401'){
        this.service.onLogout();
        this.service.toasterErr(err.error.responseMessage);
      }else{
      this.service.toasterErr('Something Went Wrong');
   }
    })
  }

}