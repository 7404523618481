<section>
    <div class="container1440 mt-5">

        <main class="middle-content">
            <div class="page_title_block">
                <h1 *ngIf="this.buttonName == 'kyc-detail'" class="page_title" style="color:#26272d">VIEW KYC DETAILS
                </h1>
                <h1 *ngIf="this.buttonName == 'redeem-view'" class="page_title" style="color:#26272d">TRANSACTION REDEEM
                    VIEW
                    DETAILS</h1>
            </div>
            <div class="content-section" style=" margin-top: -2%;">
                <div class="order-view mt30 max-WT-700 mrgn-0-auto">

                    <div class="row" *ngIf="this.buttonName == 'kyc-detail'">




                        <div class="wrapper">




                        </div>

                        <div class="content-section">
                            <div class="outer-box" style="margin-top: -5%;">

                                <div class="global-table no-radius p0">
                                    <div class="tab-content1">
                                        <div class="tab-pane1">
                                            <div class="card mt-5" style="width: 80%;margin-left: 10%;">
                                                <div class="card-header">
                                                    KYC DETAILS
                                                </div>

                                                <div class="card-body" style="width: 273%;">
                                                    <div class="row ">
                                                        <div class="col-md-6">
                                                            <div class="row mt-2">
                                                                <label class="col-md-4"
                                                                    style="font-weight: 600;">Document
                                                                    Name
                                                                </label>
                                                                <span class="col-md-1">:</span>
                                                                <label class="col-md-6"
                                                                    style="font-weight: 200;">{{viewData?.idType
                                                                    || '---'}}</label>
                                                            </div>
                                                            <div class="row mt-2">
                                                                <label class="col-md-4"
                                                                    style="font-weight: 600;">Document
                                                                    Number
                                                                    Status </label>
                                                                <span class="col-md-1">:</span>
                                                                <label class="col-md-6"
                                                                    style="font-weight: 200;">{{viewData?.idNumber
                                                                    || '---'}}</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style="margin-top: 3%">
                                                <div class="col" style="text-align: center;position: relative;">
                                                    <img [src]="viewData?.frontPage ? viewData?.frontPage: 'assets/img/adhar-front.png'"
                                                        alt="front Image" class="img"><br>
                                                    <label>(Front)</label>
                                                </div>
                                                <div class="col" style="text-align: center;">
                                                    <img [src]="viewData?.backPage ? viewData?.backPage : 'assets/img/adhar-back.png'"
                                                        alt="Back Image" class="img"><br>
                                                    <label>(Back)</label>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="two-btnline" style="margin-right: 40%;">

                                            <div class="text-left" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    style="width: 120%;margin-left: -19%;"
                                                    routerLink="/kyc-update">Back</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                    <!-----------------------------redeem View---------------------->



                    <div class="row" *ngIf="this.buttonName == 'redeem-view'">
                        <div class="main-block-innner mb40 mt40" style="margin-bottom: 1px !important;">
                            <div class="add-store-block input-style" style="padding-left: 79px;">

                                <div class="form-group row align-items-baseline" style="margin-top: 30px;">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Transaction
                                        Type</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.transactionType}}</label>
                                    </div>
                                </div>
                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Transaction
                                        Status</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label class="user-eml"
                                            style="font-size: 15px;">{{redeemObj?.transactionStatus}}</label>
                                    </div>
                                </div>
                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Status</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.status}}</label>
                                    </div>
                                </div>

                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">


                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Redeem
                                        Currency</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.redeemCurrency}}</label>
                                    </div>
                                </div>

                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Redeem
                                        Amount</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.redeemAmount}}</label>
                                    </div>
                                </div>

                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Coin Type</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.coinType}}</label>
                                    </div>
                                </div>

                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600">Coin Title</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px;">{{redeemObj?.coinTitle}}</label>
                                    </div>
                                </div>

                                <hr style="width: 90%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                <div class="form-group row align-items-baseline">
                                    <label class="col-md-5" style="font-size: 16px;font-weight: 600;    ">Coin
                                        Image</label>
                                    <div class="col-md-1">:</div>
                                    <div class="col-md-5">
                                        <label style="font-size: 15px"><img style="height: 62px;"
                                                [src]="redeemObj?.coinImage"></label>
                                    </div>
                                </div>


                            </div>
                            <div class="text-left" style="margin-left: 37%">
                                <button type="submit" class="btn  btn-theme cus-mr" routerLink="/redeem">Back</button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </main>
    </div>



    <div style="margin-top: 4%;" *ngIf="this.buttonName == 'redeem-view'">
        <div class="two-btnline" style="margin-right: 40%;">
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr" style="width: 100%;" (click)="approved()"
                    *ngIf="redeemObj?.transactionStatus == 'PENDING'">Approve</button>
            </div>
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr " style="width: 110%;margin-left: 25%;"
                    (click)=" rejected()" *ngIf="redeemObj?.transactionStatus == 'PENDING'">Reject</button>
            </div>
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr" routerLink="/redeem">Back</button>
            </div>
        </div>
    </div>
    <!-----------------------kycView--------------------------->
    <div style="margin-top: 4%;" *ngIf="this.buttonName == 'kyc-detail'">
        <div class="two-btnline" style="margin-right: 40%;">
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr" style="width: 100%;" (click)="approved()"
                    *ngIf="kycStatus== 'PENDING'">Approve</button>
            </div>
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr " style="width: 110%;margin-left: 25%;"
                    (click)=" rejected()" *ngIf="kycStatus== 'PENDING'">Reject</button>
            </div>
            <div class="text-left" style="margin-left: 1%">
                <button type="submit" class="btn  btn-theme cus-mr" style="width: 120%;margin-left: 68%;"
                    routerLink="/kyc-update">Back</button>
            </div>

        </div>
    </div>





</section>
<app-footer></app-footer>


<!-- appraved_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center;font-weight: 600;font-size: 20px;"
                            *ngIf="this.buttonName == 'kyc-detail'">KYC Approve</h3>
                        <h3 style="text-align: center;font-weight: 600;font-size: 20px;"
                            *ngIf="this.buttonName == 'redeem-view'">Redeem Approve</h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 18px;" *ngIf="this.buttonName == 'kyc-detail'">Are you sure
                                    you
                                    want to approve kyc with data entered?</p>
                                <p style="font-size: 18px;" *ngIf="this.buttonName == 'redeem-view'">Are you
                                    sure you
                                    want to approve Transaction Reedem?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        data-dismiss="modal" (click)="approveKyc()" style="background: #13674d;"
                                        *ngIf="this.buttonName == 'kyc-detail'">Yes</button>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        data-dismiss="modal" (click)="actionOnReedem('ACCEPT',redeemObj)"
                                        style="background: #13674d;"
                                        *ngIf="this.buttonName == 'redeem-view'">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;" style="background: #13674d;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- appraved_modal End -->
<!-- rejected_modal Start -->
<div class="modal fade global-modal reset-modal" id="rejectModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="modalForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 20px;font-weight: 600;text-align: center;"
                                    *ngIf="this.buttonName == 'kyc-detail'">Are you sure you want to reject kyc?
                                </p>
                                <p style="font-size: 20px;font-weight: 600;text-align: center;"
                                    *ngIf="this.buttonName == 'redeem-view'">Are you sure you want to reject
                                    Transaction?
                                    Redeem</p>
                                <div class="row" style="margin-left: 6%;">
                                    <p style="font-size: 18px;">Reason:</p>
                                    <textarea formControlName="reason" class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Your KYC documents were not approved please re-submit" rows="3"
                                        style="width: 60%;height: 115px;"></textarea>
                                </div>
                                <div class="checkbox">
                                    <label><input type="checkbox" value="">Notify User</label>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        data-dismiss="modal" (click)="rejectKyc()"
                                        style="background: #13674d;width: 25%"
                                        *ngIf="this.buttonName == 'kyc-detail'">Yes</button>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"
                                        data-dismiss="modal" (click)="actionOnReedem('REJECT',redeemObj)"
                                        style="background: #13674d;width: 25%"
                                        *ngIf="this.buttonName == 'redeem-view'">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal"
                                        style="width: 25%;background: #13674d;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- rejected_modal End -->