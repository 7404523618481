<section>
<div class="container1440 mt-5">
                  <main class="middle-content">
                      <div class="page_title_block">
                          <h1 class="page_title" style="color:#26272d">MY PROFILE</h1>
                      </div>
                      <div class="content-section" style="margin-top: -2%;">
                          <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="padding: 11px;padding-bottom: 0px;">
                                            <div class="text-left" routerLink="/change-password" >
                                          <button type="button"  style="margin-left: 66%;
                                          font-size: 15px; " class="btn btn-lg btn-success">CHANGE PASSWORD</button>
                                    </div>
                              <div class="main-block-innner mb40 " style="padding-left: 10%;">
                                                
                                  <div class="add-store-block input-style">
                                    

                                      <div class="user-profile">
                                          <div class="image-box">
                                            <img [src]="userDetail?.profilePic ? userDetail?.profilePic:'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image">
                                          </div>
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"  style="font-size: 16px;font-weight: 600;">Name </label>
                                          <div class="col-md-1"  style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label  style="font-size: 15px;">{{userDetail?.name}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"  style="font-size: 16px;font-weight: 600;">Email </label>
                                          <div class="col-md-1"  style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label   style="font-size: 15px;">{{userDetail?.email}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"   style="font-size: 16px;font-weight: 600;">Phone Number</label>
                                          <div class="col-md-1"   style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label   style="font-size: 15px;">{{userDetail?.mobileNumber}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"   style="font-size: 16px;font-weight: 600;">Date of Birth </label>
                                          <div class="col-md-1"   style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label   style="font-size: 15px;">{{userDetail?.dateOfBirth}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"  style="font-size: 16px;font-weight: 600;">Gender </label>
                                          <div class="col-md-1"   style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label  style="font-size: 15px;">{{userDetail?.gender}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="form-group row align-items-baseline">
                                          <label class="col-md-5"  style="font-size: 16px;font-weight: 600;">Address </label>
                                          <div class="col-md-1"   style="font-size: 18px;"> :</div>
                                          <div class="col-md-6">
                                              <label style="word-wrap: break-word;font-size: 15px;">{{userDetail?.address}} {{userDetail?.country}}</label>
                                          </div><hr style="width: 80%;margin-top: -1rem;border-top: 1px solid lightgrey;">
                                      </div>
                                      <div class="text-left mt40" style="margin-left: 62px;">
                                          <a class="btn btn-large max-WT-200 font-100 btn-green mr-2" [routerLink]="['/edit-profile']"
                                              routerLinkActive="router-link-active" style="font-size: 15px; margin-bottom: 17px;">EDIT PROFILE</a>
                                          <a href="javascript:void(0)" class="btn btn-large max-WT-200 font-100 btn-green ml5"
                                          [routerLink]="['/my-profile']" style="font-size: 15px; margin-bottom: 17px;">CANCEL</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </main>
</div>
</section>

<app-footer></app-footer>
