import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.css']
})
export class AdminDetailComponent implements OnInit {
  userId: any;
  Data: any;
  viewData: any;
  imageUrl: any;
  vewAdmin: FormGroup;

  constructor(private fb: FormBuilder, public route: Router, public service: MainService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((params) => {
      this.userId = params.id
      console.log('shiviuserid', this.userId)
    })
  }

  ngOnInit(): void {
    this.vewAdmin = this.fb.group({
      'checkArray': this.createLanguagesControl(),
    });
    this.viewAdmin();
  }

  createLanguagesControl() {
    const controls = this.checkboxesDataList.map(id => {
      return new FormControl(false);
    });
    return new FormArray(controls);
  }

  viewAdmin() {
    this.service.showSpinner();
    var url = 'api/v1/admin/subAdmin/' + this.userId

    this.service.get(url).subscribe((res: any) => {

      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.viewData = res['result']
        this.Data = res.result?.userPermissions;


        for (var i = 0; i < this.checkboxesDataList.length; i++) {
          for (let data of this.Data) {
            if (this.checkboxesDataList[i].id == data) {
              console.log('abc', data);
              let controlArray = <FormArray>this.vewAdmin.controls["checkArray"];
              controlArray.controls[i].patchValue(true);
            }
          }

        }
      }

    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        // this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  checkboxesDataList = [
    {
      id: 'DASHBOARD',
      label: 'Dashboard',
      isChecked: false
    },
    {
      id: 'USER MANAGEMENT',
      label: 'User Management',
      isChecked: false
    },

    {
      id: 'REDEEM MANAGEMENT',
      label: 'Redeem Management',
      isChecked: false
    },
    {
      id: 'STATIC CONTENT',
      label: 'Static Content',
      isChecked: false
    },
    {
      id: 'KYC MANAGEMENT',
      label: 'KYC Management',
      isChecked: false
    },
    {
      id: 'TOKEN MANAGEMENT',
      label: 'Token Management',
      isChecked: false
    },
   
    // {
    //   id: 'TRANSCATION MANAGEMENT',
    //   label: 'Transcation Management',
    //   isChecked: false
    // },
    {
      id: 'BIDS MANAGEMENT',
      label: 'Bids Management',
      isChecked: false
    },
    // {
    //   id: 'SUBADMIN MANAGEMENT',
    //   label: 'Sub Admin',
    //   isChecked: false
    // },
   
  ]

  viewSubAdmin(){
   
      this.service.showSpinner();
      var url = 'api/v1/admin/subAdmin/' + this.userId
  
      this.service.get(url).subscribe((res: any) => {
  
        if (res['responseCode'] == 200) {
          this.service.hideSpinner();
          this.viewData = res['result']
          this.Data = res.result?.userPermissions;
  
  
          for (var i = 0; i < this.checkboxesDataList.length; i++) {
            for (let data of this.Data) {
              if (this.checkboxesDataList[i].id == data) {
                console.log('abc', data);
                let controlArray = <FormArray>this.vewAdmin.controls["checkArray"];
                controlArray.controls[i].patchValue(true);
              }
            }
  
          }
        }
  
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          // this.service.onLogout();
          this.service.toasterErr('Unauthorized Access');
        } else {
          this.service.toasterErr('Something Went Wrong');
        }
      })
    }
  
  
  

}
