<section>
<div class="container1440 mt-5">
<main class="middle-content">
    <div class="content-section">
        
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">SUB ADMIN DETAILS</h1>
        </div>
        <div class="order-view mt30 max-WT-700 mrgn-0-auto" >
        
        <div class="user-profile">
            <div class="image-box">
                <img src="assets/img/testimonial-img1.jpg" class="profile-pic" alt="profile image">
                
            </div>
        </div>
        <form [formGroup]="vewAdmin">
        <div class="padd_6" style="margin-top: 7%;margin-left: 12%;">
            <div class="form-group row" style="padding-left: 8%;">
                <label class="col-md-5 text" for="" style="font-size: 16px;font-weight: 600;">Name</label>
                <span class="col-md-1">:</span>
                <p class="col-md-6"  style="font-size: 15px;">{{viewData?.firstName}} {{viewData?.lastName}}</p>
            </div><hr style="width: 83%;margin-top: -1rem;border-top: 1px solid lightgrey;margin-left: 7%;">
            <div class="form-group row" style="padding-left: 8%;">
                <label class="col-md-5 text" for="" style="font-size: 16px;font-weight: 600;">Email</label>
                <span class="col-md-1">:</span>
                <p class="col-md-6" style="font-size: 15px;">{{viewData?.email}}</p>
            </div><hr style="width: 83%;margin-top: -1rem;border-top: 1px solid lightgrey; margin-left: 7%;">
            <div class="form-group row" style="padding-left: 8%;">
                <label class="col-md-5 text" for="" style="font-size: 16px;font-weight: 600;">Mobile Number</label>
                <span class="col-md-1">:</span>
                <p class="col-md-6" style="font-size: 15px;">{{viewData?.mobileNumber}}</p>
            </div><hr style="width: 83%;margin-top: -1rem;border-top: 1px solid lightgrey; margin-left: 7%;" >
           
        </div>
<div style="margin-left: 24%;"> 
        <div class="col-md-6" formArrayName="checkArray" *ngFor="let data of checkboxesDataList ; let i=index" style="float: left;padding-right: 35px;padding-left: 5px;">
            <label >
              <input type="checkbox" formControlName="{{ i }}" />
              {{data.label}}
            </label>
          </div>
        </div>

        <div class="head_flt_select">
            <div class="head_flt_select input-label" style="margin-left: 1%; margin-top: 5%; ">
                <button  routerLink='/user-management-admin' type="submit" class="btn  btn-theme" style="width: 20%;margin-left: 40%;margin-bottom: 5%; margin-top: 4%;">Back</button>
            </div>
        </div>
    </form>
    </div>
    </div>
    
    
</main>
</div>
</section>
<app-footer></app-footer>
