import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ExportToCsv } from 'export-to-csv';
declare var $: any
declare var kendo: any;

@Component({
  selector: 'app-bids-management',
  templateUrl: './bids-management.component.html',
  styleUrls: ['./bids-management.component.css']
})
export class BidsManagementComponent implements OnInit {

  userForm: FormGroup;
  listing: any = [];
  id: number;
  deleted: any;
  total: any
  pageNumber:number=1
  itemsPerPage:number=5
  userid: number;
  userStatus: any;
  fromDate: any;
  maxFromDate: string;
  maxToDate: string;
  minToDate: any;
  toDate: any;
  pageSize: any=10;
  action: any;
  userstatus: any;
  userList: any=[];
  constructor(
    private router: Router, public service: MainService
  ) {

  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    })
    
    let date = new Date()
    this.fromDate =(date.getDate() > 10 ? date.getDate(): '0'+date.getDate())+'-'+( date.getMonth() > 10 ? date.getMonth() : '0'+ (date.getMonth() + 1) )+ '-' + date.getFullYear()
    this.toDate =(date.getDate() > 10 ? date.getDate(): '0'+date.getDate())+'-'+( date.getMonth() > 10 ? date.getMonth() + 1 : '0'+ (date.getMonth()+1) )+'-'+ date.getFullYear()
    this.dateValidation()
    this.getBidList()
  }

  onFromChangeDate(){
    this.minToDate = this.fromDate;
  }
  onToChangeDate(){
    this.maxFromDate = this.toDate;
  }
//----------------------date validation ----------------------//
  dateValidation(){
    let date = new Date();
    let currentDay = date.getDate() >= 10 ? date.getDate(): '0'+ date.getDate();
    let currentMonth = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1): '0'+date.getMonth();
    let currentYear = date.getFullYear();
    this.maxFromDate = currentYear + '-' + currentMonth + '-' + currentDay;
    this.maxToDate = currentYear + '-' + currentMonth + '-' + currentDay;

  }

getBidList(){
  var url = "api/v1/admin/bidList";
  let data={
    // "fromDate":this.fromDate,
    // "toDate": this.toDate
    // 'page':this.pageNumber,
    // 'limit':1000
    //'search':'gfdgfd'
  }
  this.service.showSpinner();
  this.service.postApi(url,data).subscribe(res => {
    this.service.hideSpinner();
    if (res['responseCode'] == 200) {
      this.service.toasterSucc('Records Found Successfully')
      this.userList= res['result']['docs']
    }
    this.total =res['result']['total']
  }, err => {
    this.service.hideSpinner();
    if (err['responseCode'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Something Went Wrong');
    }
  })
}

  
  // ------------------------pagination -------------------------//
  pagination(page){
    // this.totalRecords=[]
    // console.log('jh', page);
    this.pageNumber=page;
    console.log('jh', this.pageNumber);

    
  }
  //------------------------------filter by search api integration ---------------------------------//
  search() {
    let startdate = Date.parse(this.userForm.value.startdate)
    let enddate = Date.parse(this.userForm.value.enddate)
    var search = this.userForm.value.searchText;
    // if( this.userForm.value.searchText && this.userForm.value.startdate && this.userForm.controls.enddate.value){
    //  var url = "api/v1/admin/userList";
    //  let data={
    //   'fromDate':startdate,
    //   'toDate':enddate,
    //   'page':'',
    //   'limit':''
    //  }
    // }
  
      let url = "api/v1/admin/bidList";
      let data={
        'fromDate':this.userForm.value.startdate,
        'toDate':this.userForm.value.enddate,
        'page':this.pageNumber,
        'limit':1000
       }
      //var url1="account/admin/user-management/filter-user-details?fromDate="+startdate+'&toDate='+enddate
    

    this.service.postApi( url,data).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.service.toasterSucc('Records Found Successfully')
        this.userList= res['result']['docs']
      }
      if (res['responseCode'] == 404) {
       this.service.toasterErr('No Records Found, Please Try Another Date')
      }
      this.total =res['result']['total']
    })
  }

  // ------------------------------reset filter------------------------------//
  resetForm(){
    this.userForm.reset()
    this.getBidList()
  }

  //========modal=======//
  delete(id: number) {
    this.userid = id;
    $('#deleteModal').modal('show')
  }
  //------------------------------delete api integration ----------------------------------//
  deleteUser() {
    var url = 'api/v1/admin/deleteUser/'+this.userid
    this.service.deleteApi(url).subscribe((res: any) => {
     
      if (res['responseCode'] = 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User removed');
        this.getBidList()
      }
     }, err => {   
       this.service.hideSpinner();  
        if (err['responseCode'] == '401') {  
            this.service.onLogout();   
           this.service.toasterErr('Unauthorized Access'); 
         } 
      else {    
          this.service.toasterErr('Something Went Wrong');  
        } 
     })

  }

 

 

//--------------------------------pageSize ---------------------------------//
  showList(val) {
    this.pageSize = val
    this.resetForm()
  }



  // ----------------------------------------export CSV
  ExportToCsv(){
    this.service.showSpinner()
    setTimeout( r => {
      this.service.hideSpinner()
    },3000)
    let listingArr=[]
    this.userList.forEach((element,ind )=> {
      let obj ={}
      obj ={
        "S no": ind + 1,
        "User Name": element.name,
        "Email Id":  element.email ? element.email : 'N/A',
        "Date Of Birth": element.dateOfBirth ? element.dateOfBirth : 'N/A',
        "PhoneNumber": String(element.mobileNumber) ? String(element.mobileNumber) : 'N/A',
        "Status": element.status == 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
        "Registration Date": String(element.createdAt) ? String(element.createdAt).slice(0, 10) : 'N/A', 
      }
      listingArr.push(obj)
    });
    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: 'Admin user list',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
     csvExporter.generateCsv(listingArr); 
  }

  //--------------------------------export pdf ----------------------------------------
  
  exportPDF(){
    this.service.showSpinner();
    setTimeout( r => {
      this.service.hideSpinner()
    },3000);
    kendo.drawing
      .drawDOM("#pdfcontent",
        {
          paperSize: "A2",
          margin: { top: "0.8cm", bottom: "1cm" },
          scale: 0.8,
          height: 400,          
        })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, "Exported.pdf")
      });
    
  }

  BidHistory(){
    this.service.get(`https://sportspage-feeds.p.rapidapi.com/games/history`).subscribe(res=>{
      if(res['responseCode'] == 200){
        this.service.toasterSucc(res['responseMessage'])
        
      }
    },err=>{
      if(err['status'] == '401'){
        this.service.toasterErr(err['responseMessage'])     
      }
    })
  }

  BidHistoryForParticularBid(){
    this.service.get(`https://sportspage-feeds.p.rapidapi.com/games/history='n786tyjgjyt6667567rt`).subscribe(res=>{
      if(res['responseCode'] == 200){
        this.service.toasterSucc(res['responseMessage'])
        let data= res['data']
      }
    },err=>{
      if(err['status'] == '401'){
        this.service.toasterErr(err['responseMessage'])     
      }
    })
  }


}
