<section>
<div class="container1440 mt-5">
<main class="middle-content">
    <div class="content-section" >
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">ADD SUB ADMIN</h1>
        </div>
        <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="min-width: 850px;">
        <form [formGroup]="addAdmin">
            <div class="user-profile" style="padding-left: 10%;">
                <div class="image-box">
                    <img [src]="imageUrl1?imageUrl1:'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image" >
                    <label class="upload-label">
                          <input type="file" (change)="ValidateFileUpload($event)" class="hidden" accept=".png, .jpg, .jpeg" />
                          <i class="fas fa-camera" aria-hidden="true"></i>
                      </label>
                </div>
            </div>
        <div class="padd_6" style="margin: 0% 10%;">
            <div class="form-group row">
                <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">User Name:</label>
                <div class="col-md-9">
                <input type="text" class="form-control" formControlName="userName" >
                <span class="error"
                         *ngIf="addAdmin.get('userName').hasError('required') && (addAdmin.get('userName').dirty || addAdmin.get('userName').touched )"
                            class="error">
                            <p>
                                *Please enter user name.
                            </p>
                    </span>
                  <!-- <span class="error"
                         *ngIf="addAdmin.get('userName').hasError('pattern') && addAdmin.get('userName').dirty"
                            class="error">
                            <p>
                                *Please enter valid user name.
                            </p>
                    </span> -->
            </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">First Name:</label>
                <div class="col-md-9">
                <input type="text" class="form-control" formControlName="name" >
                <span class="error"
                         *ngIf="addAdmin.get('name').hasError('required') && (addAdmin.get('name').dirty || addAdmin.get('name').touched )"
                            class="error">
                            <p>
                                *Please enter first name.
                            </p>
                    </span>
                  <span class="error"
                         *ngIf="addAdmin.get('name').hasError('pattern') && addAdmin.get('name').dirty"
                            class="error">
                            <p>
                                *Please enter valid first name.
                            </p>
                    </span>
            </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 " for="" style="font-size: 16px;font-weight: 600;">Last Name:</label>
                <div class="col-md-9">
                <input type="tel" class="form-control" formControlName="lastname" >
                <span class="error"
                         *ngIf="addAdmin.get('lastname').hasError('required') && (addAdmin.get('lastname').dirty || addAdmin.get('lastname').touched )"
                            class="error">
                            <p>
                                *Please enter last name.
                            </p>
                    </span>
                  <span class="error"
                         *ngIf="addAdmin.get('lastname').hasError('pattern') && addAdmin.get('lastname').dirty"
                            class="error">
                            <p>
                                *Please enter valid last name.
                            </p>
                    </span>
            </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">Email:</label>
                <div class="col-md-9">
                <input type="email" class="form-control" formControlName="email"
                maxlength="64" />
                <span class="error">
                    <p *ngIf="addAdmin.get('email').hasError('required') && (addAdmin.get('email').dirty || addAdmin.get('email').touched )"
                        class="error" padding>*Please enter email address.</p>
                </span>
              <span class="error">
                  <p *ngIf="addAdmin.get('email').hasError('pattern') && addAdmin.get('email').dirty"
                      class="error" padding>*Please enter valid email address.</p>
              </span>
            </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 " for="" style="font-size: 16px;font-weight: 600;">Mobile Number:</label>
                <div class="col-md-9">
                <input type="tel" class="form-control" formControlName="phoneNo" >
                <span class="error"
                         *ngIf="addAdmin.get('phoneNo').hasError('required') && (addAdmin.get('phoneNo').dirty || addAdmin.get('phoneNo').touched )"
                            class="error">
                            <p>
                                *Please enter mobile number.
                            </p>
                    </span>
                  <span class="error"
                         *ngIf="addAdmin.get('phoneNo').hasError('pattern') && addAdmin.get('phoneNo').dirty"
                            class="error">
                            <p>
                                *Please enter valid mobile number.
                            </p>
                    </span>
            </div>
            </div>
            
            
            <div class="form-group row">
                <label class="col-md-3" for=""  style="font-size: 16px;font-weight: 600;">Password:</label>
                <div class="col-md-9">
                <input type="password" class="form-control" formControlName="password" >
                <div
                                      *ngIf="addAdmin.get('password').invalid && (addAdmin.get('password').touched || addAdmin.get('password').dirty)"
                                      class="text-danger">
                                      <span *ngIf="addAdmin.get('password').hasError('required')">
                                        *Please enter password.</span>
                                      <span *ngIf="addAdmin.get('password').hasError('pattern')">
                                        *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                                        number and 1 special character.</span>
                                    </div>
            </div>
        </div>
            <div class="form-group row">
                <label class="col-md-3 " for=""  style="font-size: 16px;font-weight: 600;">Confirm Password:</label>
                <div class="col-md-9">
                <input type="password" class="form-control" formControlName="confirmPassword" >
                <div *ngIf="addAdmin.get('confirmPassword').dirty" class="text-danger">
                    <span *ngIf="addAdmin.get('confirmPassword').value != addAdmin.get('password').value">*Password doesn't match with confirm password.
                    </span>
                  </div>
            </div>
        </div>
            
        </div>
       <span style=" margin: 3px 10%;
       font-size: 16px;
       font-weight: 600;">Select Permissions:</span>
        
        <div *ngFor="let data of checkboxesDataList ; let i=index" style="margin-left: 31%;">
            <label >
              <input type="checkbox" [value]="data.id" (change)="onCheckboxChange($event)" />
              {{data.label}}
            </label>
          </div>
        
       
        
        </form>
        <div class="row" style="margin-left: 30%;margin-top: 9%; margin-bottom: 5%;">
            <div class="head_flt_select">
                <div class="head_flt_select input-label" >
                    <button type="submit" class="btn  btn-theme" style="width: 200%;" [routerLink]="['/user-management-admin']" >Cancel</button>
                </div>
            </div>
            <div class="head_flt_select">
                <div class="head_flt_select input-label" >
                    <button type="submit" class="btn  btn-theme" style="width: 200%;margin-left: 200%;"  (click)="addSubAdmin()">Save</button>
                </div>
            </div>
        </div> 
    </div>
    </div>
    
</main>
</div>


<div class="container1440 mt-5" *ngIf="aaa">
    <main class="middle-content">
        <div class="content-section" >
            <div class="page_title_block pL0 pR0">
                <h1 class="page_title" style="color:#26272d">ADD SUB ADMIN</h1>
            </div>
            <div class="order-view mt30 max-WT-700 mrgn-0-auto" style="min-width: 850px;">
            <form [formGroup]="addAdmin">
                <div class="user-profile" style="padding-left: 10%;">
                    <div class="image-box">
                        <img [src]="imageUrl1?imageUrl1:'assets/img/testimonial-img1.jpg'" class="profile-pic" alt="profile image" >
                        <label class="upload-label">
                              <input type="file" (change)="ValidateFileUpload($event)" class="hidden" accept=".png, .jpg, .jpeg" />
                              <i class="fas fa-camera" aria-hidden="true"></i>
                          </label>
                    </div>
                </div>
            <div class="padd_6" style="margin: 0% 10%;">
                <div class="form-group row">
                    <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">User Name:</label>
                    <div class="col-md-9">
                    <input type="text" class="form-control" formControlName="userName" >
                    <span class="error"
                             *ngIf="addAdmin.get('userName').hasError('required') && (addAdmin.get('userName').dirty || addAdmin.get('userName').touched )"
                                class="error">
                                <p>
                                    *Please enter user name.
                                </p>
                        </span>
                      <!-- <span class="error"
                             *ngIf="addAdmin.get('userName').hasError('pattern') && addAdmin.get('userName').dirty"
                                class="error">
                                <p>
                                    *Please enter valid user name.
                                </p>
                        </span> -->
                </div>
                </div>
    
                <div class="form-group row">
                    <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">First Name:</label>
                    <div class="col-md-9">
                    <input type="text" class="form-control" formControlName="name" >
                    <span class="error"
                             *ngIf="addAdmin.get('name').hasError('required') && (addAdmin.get('name').dirty || addAdmin.get('name').touched )"
                                class="error">
                                <p>
                                    *Please enter first name.
                                </p>
                        </span>
                      <span class="error"
                             *ngIf="addAdmin.get('name').hasError('pattern') && addAdmin.get('name').dirty"
                                class="error">
                                <p>
                                    *Please enter valid first name.
                                </p>
                        </span>
                </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 " for="" style="font-size: 16px;font-weight: 600;">Last Name:</label>
                    <div class="col-md-9">
                    <input type="tel" class="form-control" formControlName="lastname" >
                    <span class="error"
                             *ngIf="addAdmin.get('lastname').hasError('required') && (addAdmin.get('lastname').dirty || addAdmin.get('lastname').touched )"
                                class="error">
                                <p>
                                    *Please enter last name.
                                </p>
                        </span>
                      <span class="error"
                             *ngIf="addAdmin.get('lastname').hasError('pattern') && addAdmin.get('lastname').dirty"
                                class="error">
                                <p>
                                    *Please enter valid last name.
                                </p>
                        </span>
                </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3" for="" style="font-size: 16px;font-weight: 600;">Email:</label>
                    <div class="col-md-9">
                    <input type="email" class="form-control" formControlName="email"
                    maxlength="64" />
                    <span class="error">
                        <p *ngIf="addAdmin.get('email').hasError('required') && (addAdmin.get('email').dirty || addAdmin.get('email').touched )"
                            class="error" padding>*Please enter email address.</p>
                    </span>
                  <span class="error">
                      <p *ngIf="addAdmin.get('email').hasError('pattern') && addAdmin.get('email').dirty"
                          class="error" padding>*Please enter valid email address.</p>
                  </span>
                </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 " for="" style="font-size: 16px;font-weight: 600;">Mobile Number:</label>
                    <div class="col-md-9">
                    <input type="tel" class="form-control" formControlName="phoneNo" >
                    <span class="error"
                             *ngIf="addAdmin.get('phoneNo').hasError('required') && (addAdmin.get('phoneNo').dirty || addAdmin.get('phoneNo').touched )"
                                class="error">
                                <p>
                                    *Please enter mobile number.
                                </p>
                        </span>
                      <span class="error"
                             *ngIf="addAdmin.get('phoneNo').hasError('pattern') && addAdmin.get('phoneNo').dirty"
                                class="error">
                                <p>
                                    *Please enter valid mobile number.
                                </p>
                        </span>
                </div>
                </div>
                
                
                <div class="form-group row">
                    <label class="col-md-3" for=""  style="font-size: 16px;font-weight: 600;">Password:</label>
                    <div class="col-md-9">
                    <input type="password" class="form-control" formControlName="password" >
                    <div
                                          *ngIf="addAdmin.get('password').invalid && (addAdmin.get('password').touched || addAdmin.get('password').dirty)"
                                          class="text-danger">
                                          <span *ngIf="addAdmin.get('password').hasError('required')">
                                            *Please enter password.</span>
                                          <span *ngIf="addAdmin.get('password').hasError('pattern')">
                                            *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                                            number and 1 special character.</span>
                                        </div>
                </div>
            </div>
                <div class="form-group row">
                    <label class="col-md-3 " for=""  style="font-size: 16px;font-weight: 600;">Confirm Password:</label>
                    <div class="col-md-9">
                    <input type="password" class="form-control" formControlName="confirmPassword" >
                    <div *ngIf="addAdmin.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="addAdmin.get('confirmPassword').value != addAdmin.get('password').value">*Password doesn't match with confirm password.
                        </span>
                      </div>
                </div>
            </div>
                
            </div>
           <span style=" margin: 3px 10%;
           font-size: 16px;
           font-weight: 600;">Select Permissions:</span>
            
            <div *ngFor="let data of checkboxesDataList ; let i=index" style="margin-left: 31%;">
                <label >
                  <input type="checkbox" [value]="data.id" (change)="onCheckboxChange($event)" />
                  {{data.label}}
                </label>
              </div>
            
           
            
            </form>
            <div class="row" style="margin-left: 30%;margin-top: 9%; margin-bottom: 5%;">
                <div class="head_flt_select">
                    <div class="head_flt_select input-label" >
                        <button type="submit" class="btn  btn-theme" style="width: 200%;" [routerLink]="['/user-management-admin']" >Cancel</button>
                    </div>
                </div>
                <div class="head_flt_select">
                    <div class="head_flt_select input-label" >
                        <button type="submit" class="btn  btn-theme" style="width: 200%;margin-left: 200%;"  (click)="addSubAdmin()">Save</button>
                    </div>
                </div>
            </div> 
        </div>
        </div>
        
    </main>
    </div>
</section>
<!-- <footer style="background-color: #13674d;">
    <div class="container-fluid">
        <p class="copyright">Copyright @ 2020 All Rights Reserved<strong class="theme-text-color"></strong> 
            </p>
    </div>
</footer> -->

<!-- delete_modal Start -->
<div class="modal fade global-modal reset-modal" id="deleteModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Delete Sub Admin </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to delete this Sub Admin?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2 " style="width: 25%;"  >Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal" style="width: 25%;">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- delete_modal End -->





<app-footer></app-footer>