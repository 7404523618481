import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';
import { FormGroup,FormBuilder,Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-view-bids',
  templateUrl: './view-bids.component.html',
  styleUrls: ['./view-bids.component.css']
})
export class ViewBidsComponent implements OnInit {

  constructor(private router: Router , public service:MainService,private active:ActivatedRoute) { }

  ngOnInit(): void {
  }

  viewParticularBids(){
    this.service.get(`https://sportspage-feeds.p.rapidapi.com/games`).subscribe(res=>{
      if(res['responseCode'] == 200){
        this.service.toasterSucc(res['responseMessage'])
        
      }
    },err=>{
      if(err['status'] == '401'){
        this.service.toasterErr(err['responseMessage'])     
      }
    })
  }
}
