<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#e0a354" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<app-sidebar></app-sidebar>
<router-outlet></router-outlet>
<!-- <footer style="background: #13674d;" *ngIf="router.url != 'dashboard'">
  <div class="container-fluid" >
      <p class="copyright">Copyright @ 2020 All Rights Reserved  <strong class="theme-text-color"></strong> 
          </p>
  </div>
</footer> -->
