<div class="wrapper" style="overflow-x: hidden;">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title" style="color:#26272d; margin-top: 7%;" >DASHBOARD</h1>
           
        </div>
        <div class="content-section dashboard-block" style="padding-bottom: 37px">
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background-color: #ff6969;text-align: center; color: white;">
                    <div>
                        <i class="fa fa-user" style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%;margin-left: 3%;"> Total Users </h6>
                    <h4>{{count.total_user}}</h4>
                </li>

                <li class="dashbord-box" style=" background-color: #0099B8;text-align: center; color: white;">
                    <div>
                        <i class="fas fa-user-ninja" style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%;margin-left: 3%;"> Total Active Users</h6>
                    <h4>{{count.totalActiveUser}}</h4>
                </li>
                <li class="dashbord-box" style="background: #089E8F;text-align: center; color: white;">
                    <div>
                        <i class="fas fa-user-slash" style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%;margin-left: 3%;"> Total Blocked Users</h6>
                    <h4>{{count.BLOCK}}</h4>
                </li>
            </ul>
            <ul class="dash_list1 d-flex w-100 flex-wrap ">
                <li class="dashbord-box" style="background: #0099B8;text-align: center; color: white;">
                    <div>
                        <i class="fas fa-file " style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%;margin-left: 3%;">Total KYC Requested</h6>
                    <h4>{{count.totalKycRequest}}</h4>
                </li>
                <li class="dashbord-box" style="background: #089E8F;text-align: center; color: white;">
                    <div>
                        <i class="fas fa-file" style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%;margin-left: 3%;">Total KYC Rejected </h6>
                    <h4>{{count.totalKycReject}}</h4>
                </li>
                <li class="dashbord-box" style="background: #ff6969;text-align: center; color: white;">
                    <div>
                        <i class="fas fa-file" style="font-size: 57px;"></i>
                    </div>
                    <h6 style="margin-top: 6%; margin-left: 3%;">Total KYC Approved</h6>
                    <h4>{{count.totalKycApprove}}</h4>
                </li>
            </ul>
        </div>
    </main>
</div>
<app-footer></app-footer>