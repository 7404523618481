import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MainService } from 'src/app/provider/main.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ExportToCsv } from 'export-to-csv';

declare var $: any
declare var kendo: any;


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  userForm: FormGroup;
  listing: any = [];
  id: number;
  deleted: any;
  total: any
  pageNumber: number = 1
  itemsPerPage: number = 10
  userid: number;
  userStatus: any;
  fromDate: any;
  maxFromDate: string;
  maxToDate: string;
  minToDate: any;
  toDate: any;
  pageSize: any = 10;
  action: any;
  userstatus: any;
  userList: any = [];
  totalRecords: any

  constructor(private router: Router, public service: MainService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
    })

    let date = new Date()
    this.fromDate = (date.getDate() > 10 ? date.getDate() : '0' + date.getDate()) + '-' + (date.getMonth() > 10 ? date.getMonth() : '0' + (date.getMonth() + 1)) + '-' + date.getFullYear()
    this.toDate = (date.getDate() > 10 ? date.getDate() : '0' + date.getDate()) + '-' + (date.getMonth() > 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) + '-' + date.getFullYear()
    this.dateValidation()
    this.getUserList()
  }

  onFromChangeDate() {
    this.minToDate = this.fromDate;
  }
  onToChangeDate() {
    this.maxFromDate = this.toDate;
  }
  //----------------------date validation ----------------------//
  dateValidation() {
    let date = new Date();
    let currentDay = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    let currentMonth = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : '0' + date.getMonth()+1;
    console.log("current month::", date.getMonth())
    let currentYear = date.getFullYear();
    this.maxFromDate = currentYear + '-' + currentMonth + '-' + currentDay;
    this.maxToDate = currentYear + '-' + currentMonth + '-' + currentDay;

  }

  getUserList() {
    var url = "api/v1/admin/userList";
    let data = {
      'page': this.pageNumber,
      'limit': 1000
    }
    this.service.showSpinner();
    this.service.postApi(url, data).subscribe(res => {  
      if (res['responseCode'] == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc('User list fetched successfully')
        this.userList = res['result']['docs']
      }
      this.total = res['result']['total']
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access');
      } else {
        this.service.toasterErr('Something went wrong');
      }
    })
  }


  // ------------------------pagination -------------------------//
  pagination(page) {
    this.pageNumber = page;
    console.log('jh', this.pageNumber);


  }
  //------------------------------filter by search api integration ---------------------------------//
  search() {
    var search = this.userForm.value.searchText;
    let url = "api/v1/admin/userList";
    let data = {
      'fromDate': this.userForm.value.startdate,
      'toDate': this.userForm.value.enddate,
      'page': this.pageNumber,
      'search': search,
      'limit': 1000
    }
    this.service.showSpinner()
    this.service.postApi(url, data).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner()
        this.service.toasterSucc('User list fetched successfully')
        this.userList = res['result']['docs']
      }
      if (res['responseCode'] == 404) {
        this.service.hideSpinner()
        this.service.toasterErr('No records found')
      }
      if (res['responseCode'] == 501) {
        this.service.hideSpinner()
        this.service.toasterErr(res['responseMessage'])
      }
      if (res['responseCode'] == 500) {
        this.service.hideSpinner()
        this.service.toasterErr(res['responseMessage'])
      }
      this.total = res['result']['total']
    })
  }

  // ------------------------------reset filter------------------------------//
  resetForm() {
    this.userForm.reset()
    this.getUserList()
  }

  //========modal=======//
  delete(id: number) {
    this.userid = id;
    $('#deleteModal').modal('show')
  }
  //------------------------------delete api integration ----------------------------------//
  deleteUser() {
    var url = 'api/v1/admin/deleteUser/' + this.userid
    this.service.showSpinner()
    this.service.deleteApi(url).subscribe((res: any) => {
      if (res['responseCode'] = 200) {
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User removed');
        this.getUserList()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access');
      }
      else {
        this.service.toasterErr('Something went wrong');
      }
    })

  }

  RemoveUser() {
    var url = 'api/v1/admin/deleteUser/' + this.userid
    this.service.showSpinner();
    this.service.deleteApi(url).subscribe((res: any) => {

      if (res['responseCode'] = 200) {
        this.service.hideSpinner();
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User removed successfully');
        this.getUserList()
      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized access');
      }
      else {
        this.service.toasterErr('Something went wrong');
      }
    })

  }

  //-------------------------block api integration------------------------//
  block(status, id) {
    this.userid = id
    this.userstatus = status
    $('#block').modal('show')
  }

  blockUser() {
    var url = 'api/v1/admin/blockUnblockUser'
    let data = {
      'userId': this.userid
    }
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res['responseCode'] == 200) {
        this.service.hideSpinner()
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('User blocked successfully');
          this.getUserList()
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User activated successfully');
          this.getUserList()
        }

      }
    }, err => {
      this.service.hideSpinner();
      if (err['responseCode'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Something Went Wrong');
      }
    })
  }

  //---------------------------------- Delete / Block Function--------------//
  openModal(action, userId) {
    this.userid = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  //------------------- user details navigation------------------------------//
  userDetails(id) {
    this.router.navigate(['/user-details', id])

  }

  walletdetail(id) {
    this.router.navigate(['walletdetails/' + id])
  }

  //--------------------------------pageSize ---------------------------------//
  showList(val) {
    this.pageSize = val
    this.resetForm()
  }


  //----------------------------------export User---------------------------------//
  exportAsXLSX() {
    let dataArr = [];
    this.userList.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S.No": ind + 1,
        "User Name": element.name,
        "Email Id": element.email ? element.email : 'N/A',
        "Date Of Birth": element.dateOfBirth ? element.dateOfBirth : 'N/A',
        "Mobile Number": element.mobileNumber ? element.mobileNumber : 'N/A',
        "Status": element.status == 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
        "Registration Date & Time": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
      }
      dataArr.push(obj)
    })

    this.service.exportAsExcelFile(dataArr, 'Admin User List');
  }
  // ----------------------------------------export CSV
  ExportToCsv() {
    this.service.showSpinner()
    setTimeout(r => {
      this.service.hideSpinner()
    }, 3000)
    let listingArr = []
    this.userList.forEach((element, ind) => {
      let obj = {}
      obj = {
        "S.No": ind + 1,
        "User Name": element.name,
        "Email Id": element.email ? element.email : 'N/A',
        "Date Of Birth": element.dateOfBirth ? element.dateOfBirth : 'N/A',
        "Mobile Number": String(element.mobileNumber) ? String(element.mobileNumber) : 'N/A',
        "Status": element.status == 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
        "Registration Date & Time": String(element.createdAt) ? String(element.createdAt).slice(0, 10) : 'N/A',
      }
      listingArr.push(obj)
    });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Admin user list',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(listingArr);
  }

  //--------------------------------export pdf ----------------------------------------

  exportPDF() {
    this.service.showSpinner();
    setTimeout(r => {
      this.service.hideSpinner()
    }, 3000);
    kendo.drawing
      .drawDOM("#pdfcontent",
        {
          paperSize: "A2",
          margin: { top: "0.8cm", bottom: "1cm" },
          scale: 0.8,
          height: 400,
        })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, "Exported.pdf")
      });

  }


}
